import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import './_GetChrome.scss'

const GetChrome = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  return (
    <>
      <div className="get-chrome--footnote">
        This experience works best with Google Chrome browsers on laptops or desktop computers. Get
        it{' '}
        <a href="https://www.google.com/intl/en_ca/chrome/" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </div>

      <Popover
        isOpen={isPopoverOpen}
        positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
        content={
          <div className="get-chrome--popover-content">
            <div className="get-chrome--popover-close" onClick={() => setIsPopoverOpen(false)}>
              <img src="/assets/images/outline_close_black_24dp.png" alt="close" />
            </div>
            <h2>If the videos and audio files are not loading: </h2>
            <br />
            <ol>
              <li>
                Please make sure you are using an up to date version of Chrome as your browser
              </li>
              <li>
                If you have the option, please view on a laptop or desktop rather than a tablet or
                phone
              </li>
              <li>
                Please check your wifi signal, if it is weak the media will take longer to load
              </li>
              <li>
                If you have checked all of the above and still have issues loading the site you can
                click here to watch a{' '}
                <a href="https://vimeo.com/511704393/02ecd6eb9b" rel="noreferrer" target="_blank">
                  40-minute video documentation
                </a>{' '}
                of the web performance.
              </li>
            </ol>
          </div>
        }
      >
        <div
          className="get-chrome--popover-trigger"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          Having trouble?
        </div>
      </Popover>
    </>
  )
}
export default GetChrome

import React, { useEffect, useReducer, useRef } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import i18n from 'i18next'
import { cloneDeep } from 'lodash'
import { initReactI18next } from 'react-i18next'
import ReactAudioPlayer from 'react-audio-player'
import gsap from 'gsap'

import en_copy from './data/copy/en.json'

import './App.scss'
import GeneralLayout from './components/layout/GeneralLayout'
import About from './components/screens/About'
import Home from './components/screens/Home'

import { defaultMainState, MainContext } from './components/state/MainContext'
import Constellation from './components/screens/Constellation'
import { mainReducer } from './components/state/MainReducer'
import YingYang from './components/screens/YingYang'
import YingYangDetails from './components/screens/YingYangDetails'
import UserZodiac from './components/screens/UserZodiac'
import NotFound from './components/screens/NotFound'
import Intro from './components/screens/Intro'
import PrivateRoute from './PrivateRoute'
import { setSystemSpecs } from './components/state/MainActions'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en_copy,
      },
    },
    fallbackLng: ['en'],
    interpolation: {
      escapeValue: false,
    },
  })

function App() {
  const [state, dispatch] = useReducer(mainReducer, cloneDeep(defaultMainState))
  const audio = useRef()

  useEffect(() => {
    const system = {}
    system.isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
    system.iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStrea

    dispatch(setSystemSpecs(system))
  }, [])

  /* useEffect(() => {
    const audio = document.getElementsByTagName('audio')
    if (location.pathname.indexOf('yin-yang') >= 0 && lastPath.indexOf('yin-yang') >= 0) {
      if (
        location.pathname === '/yin-yang/core/yang' ||
        location.pathname === '/yin-yang/core/yin'
      ) {
        gsap.killTweensOf(audio)
        gsap.fromTo(audio, { volume: 0.5 }, { volume: 0, duration: 1 })
      } else {
        gsap.killTweensOf(audio)
        gsap.fromTo(audio, { volume: 0 }, { volume: 0.5, duration: 1 })
      }
    } else {
      gsap.killTweensOf(audio)
      gsap.fromTo(audio, { volume: 0.5 }, { volume: 0, duration: 1 })
    }
    setLastPath(location.pathname)

    clearTimeout(delay.current)
    delay.current = setTimeout(() => {
      switchAudio(location.pathname, audio)
    }, 1000)
  }, [location.pathname])*/

  useEffect(() => {
    if (audio && audio.current && audio.current.audioEl) {
      gsap.killTweensOf(audio.current.audioEl.current)
      gsap.fromTo(audio.current.audioEl.current, { volume: 0 }, { volume: 0.5, duration: 1 })
    }
  }, [state.audioSrc])

  useEffect(() => {
    if (audio && audio.current && audio.current.audioEl) {
      gsap.killTweensOf(audio.current.audioEl.current)
      if (state.currentVideo !== '') {
        if (state.system.iOS) {
          audio.current.audioEl.current.pause()
        } else {
          audio.current.audioEl.current.volume = 0.5
          setTimeout(() => {
            audio.current.audioEl.current.volume = 0.5
            gsap.to(audio.current.audioEl.current, { volume: 0, duration: 1 })
          }, 500)
        }
      } else {
        if (state.system.iOS) {
          audio.current.audioEl.current.play()
        } else {
          setTimeout(() => {
            audio.current.audioEl.current.volume = 0
            gsap.to(audio.current.audioEl.current, { volume: 0.5, duration: 1 })
          }, 500)
        }
      }
    }
  }, [state.currentVideo, state.system.iOS])

  return (
    <MainContext.Provider value={[state, dispatch]}>
      <div className="App">
        <ReactAudioPlayer ref={audio} src={state.audioSrc} loop autoPlay volume={0.5} />
        <Router>
          <GeneralLayout>
            <AnimatedSwitch
              atEnter={{ scale: 0.8, opacity: 0 }}
              atLeave={{ scale: 0.8, opacity: 0 }}
              atActive={{ scale: 1, opacity: 1 }}
              mapStyles={(styles) => ({
                opacity: styles.opacity,
                transform: `scale(${styles.scale})`,
              })}
            >
              <Route exact path="/" component={Intro} />

              <PrivateRoute exact path="/about">
                <About />
              </PrivateRoute>
              <PrivateRoute exact path={['/home', '/home/start']}>
                <Home />
              </PrivateRoute>
              <PrivateRoute exact path="/yin-yang">
                <YingYang />
              </PrivateRoute>
              <PrivateRoute path="/constellation/:constellationIndex">
                <Constellation />
              </PrivateRoute>
              <PrivateRoute path="/yin-yang/:element/:variation">
                <YingYangDetails />
              </PrivateRoute>
              <PrivateRoute path="/yin-yang/your-zodiac">
                <UserZodiac />
              </PrivateRoute>
              <Route component={NotFound} />
            </AnimatedSwitch>
          </GeneralLayout>
        </Router>
      </div>
    </MainContext.Provider>
  )
}

export default App

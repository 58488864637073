import React, { useContext, useEffect, useState } from 'react'
import './_YingYang.scss'
import ElementsCircle from '../ying-yang/ElementsCircle'
import Vimeo from '@u-wave/react-vimeo'
import { Redirect } from 'react-router-dom'
import { MainContext } from '../state/MainContext'

const YingYang = () => {
  const [state] = useContext(MainContext)
  const [redirection, setRedirection] = useState('')

  useEffect(() => {
    if (state.initialInstructions === 1) {
      setRedirection('/')
    }
  }, [])
  return (
    <>
      {redirection !== '' && <Redirect push to={redirection} />}

      <div className="ying-yang--background">
        <div className="ying-yang--background">
          <Vimeo
            style={{
              width: '100vw',
              height: '56.25vw',
              minHeight: '100vh',
              minWidth: '177.77vh',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            video="504586513"
            controls={false}
            showTitle={false}
            showByline={false}
            loop={true}
            autoplay
            width={'100%'}
            height={'100%'}
            responsive={true}
          />
        </div>
        <ElementsCircle />
      </div>
    </>
  )
}

export default YingYang

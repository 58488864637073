import React from 'react'
import { useTranslation } from 'react-i18next'
import './_SkipBirthday.scss'

const SkipBirthday = ({ onSkip, onCancel }) => {
  const { t } = useTranslation()
  return (
    <div className="skip-birthday--container">
      <div className="skip-birthday--text-container">
        <div className="skip-birthday--title">{t('home.skip_birtdhay.title')}</div>
        <div
          className="skip-birthday--subtitle"
          dangerouslySetInnerHTML={{ __html: t('home.skip_birtdhay.subtitle_1') }}
        />
        <div
          className="skip-birthday--subtitle"
          dangerouslySetInnerHTML={{ __html: t('home.skip_birtdhay.subtitle_2') }}
        />
        <div className="skip-birthday--buttons-container">
          <div className="skip-birthday--buttons-wrapper">
            <div className="skip-birthday--button" role="button" onClick={onCancel}>
              {t('home.skip_birtdhay.buttons.no.title')}
            </div>
            <div className="skip-birthday--button-subtitle">
              {t('home.skip_birtdhay.buttons.no.subtitle')}
            </div>
          </div>
          <div className="skip-birthday--buttons-wrapper">
            <div className="skip-birthday--button" role="button" onClick={onSkip}>
              {t('home.skip_birtdhay.buttons.yes.title')}
            </div>
            <div className="skip-birthday--button-subtitle">
              {t('home.skip_birtdhay.buttons.yes.subtitle')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkipBirthday

import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, withRouter } from 'react-router-dom'
import gsap from 'gsap'
import Header from './Header'
import './_GeneralLayout.scss'
import { MainContext } from '../state/MainContext'
import { setSoundSrc } from '../state/MainActions'
const GeneralLayout = ({ children, history }) => {
  const location = useLocation()
  const [state, dispatch] = useContext(MainContext)

  return (
    <main className="layout">
      {(location.pathname.indexOf('/about') >= 0 ||
        location.pathname.indexOf('/constellation') >= 0 ||
        location.pathname.indexOf('/yin-yang') >= 0) && (
        <>
          {!location.pathname.match(/\/yin-yang\/[a-z]*\/(yin|yang)/g) &&
            !state.currentVideo &&
            !(location.pathname.indexOf('/constellation') >= 0 && state.experienceFinished > 0) && (
              <header className="layout--header">
                <Header location={location} />
                <div className="layout--yingyang">
                  <NavLink
                    exact
                    to="/yin-yang"
                    onClick={() => {
                      dispatch(
                        setSoundSrc(`/assets/audio/yin-yang${state.system.iOS ? '_ios' : ''}.aac`)
                      )
                    }}
                  >
                    <img src="/assets/images/YingYangMenu.png" alt="Go to Ying Yang movies" />
                  </NavLink>
                </div>
              </header>
            )}
        </>
      )}
      <section className="layout--container">{children}</section>
    </main>
  )
}

export default withRouter(GeneralLayout)

import React from 'react'
import './_Card.scss'

const Card = ({ onClose, children }) => {
  return (
    <div className="card--background">
      {!!onClose && (
        <div className="card--close" onClick={onClose}>
          X
        </div>
      )}
      <div className="card--content">{children}</div>
    </div>
  )
}
export default Card

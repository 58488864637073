import React from 'react'
import './_NotFound.scss'
import Background from '../common/Background'
import { NavLink } from 'react-router-dom'
import GetChrome from '../common/GetChrome'
import Card from '../common/Card'
const NotFound = () => {
  return (
    <Background>
      <div>
        <Card>
          <div>
            Opps!
            <br />
            We cannot find the page you're looking for
            <br />
            Maybe start <NavLink to="/">from the beginning?</NavLink>
          </div>
        </Card>
        <GetChrome />
      </div>
    </Background>
  )
}
export default NotFound

import React, { useContext, useEffect, useState } from 'react'
import './_YingYangDetails.scss'
import { Redirect, useParams } from 'react-router-dom'
import ElementDetails from '../ying-yang/ElementDetails'
import Vimeo from '@u-wave/react-vimeo'
import { MainContext } from '../state/MainContext'

const YingYangDetails = () => {
  const [state] = useContext(MainContext)
  const { element, variation } = useParams()
  const [video, setVideo] = useState('')
  const [videoDone, setVideoDone] = useState(false)
  const [redirection, setRedirection] = useState('')
  const [videoPlayerClass, setVideoPlayerClass] = useState('')
  const [videoPreloading, setVideoPreloading] = useState(false)

  useEffect(() => {
    if (state.initialInstructions === 1) {
      setRedirection('/')
    } else {
      if (element === 'core') {
        setVideo(state.yingYang.core[variation])
      } else {
        const [currentElement] = state.yingYang.elements.filter((el) => el.key === element)
        setVideo(currentElement[variation])
      }
      if (state.system.iOS) {
        setVideoPlayerClass('constellation--videoPlayer-idle')
      } else {
        setVideoPreloading(true)
      }
    }
  }, [])

  const onVideoStart = () => {
    setVideoPlayerClass('constellation--videoPlayer-idle')
    setVideoPreloading(false)
  }

  const onVideoProgress = (event) => {
    const { seconds, duration } = event
    if (duration - seconds < 0.51 && videoPlayerClass === 'constellation--videoPlayer-idle') {
      setVideoPlayerClass('constellation--videoPlayer-fadeout')
    }
  }

  const onVideoEnd = () => {
    setVideo('')
    setVideoPlayerClass('')
    if (element === 'core') {
      setRedirection('/yin-yang')
    } else {
      setVideoDone(true)
    }
  }

  return (
    <>
      {redirection !== '' && <Redirect push to={redirection} />}
      <div className="yingyang-details--container">
        {!videoDone && video !== '' && (
          <>
            {!state.system.iOS && (
              <div
                className="constellation--cover"
                style={{
                  backgroundColor:
                    videoPlayerClass === 'constellation--videoPlayer-idle'
                      ? 'rgba(0,0,0,1)'
                      : 'rgba(0,0,0,0)',
                }}
              >
                {videoPreloading && <img src="/assets/images/loading.gif" />}
              </div>
            )}
            <Vimeo
              className={`constellation--videoPlayer ${videoPlayerClass}`}
              style={{ width: '100vw', height: '100vh' }}
              video={video}
              controls={true}
              showTitle={false}
              showByline={false}
              autoplay={!state.system.iOS}
              width={'100%'}
              height={'100%'}
              responsive={true}
              onEnd={onVideoEnd}
              onPlay={onVideoStart}
              onTimeUpdate={onVideoProgress}
            />
          </>
        )}
        {videoDone && <ElementDetails element={element} variation={variation} />}
      </div>
    </>
  )
}

export default YingYangDetails

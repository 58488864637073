import React from 'react'
import { useTranslation } from 'react-i18next'
import './_InitialInstructions.scss'
const InitialInstructions = ({ onSkip }) => {
  const { t } = useTranslation()
  return (
    <div className="initial-instructions--container">
      <div className="initial-instructions--text-container">
        <div
          className="initial-instructions--title"
          dangerouslySetInnerHTML={{ __html: t('home.initial_instructions.title') }}
        />
        <div
          className="initial-instructions--subtitle"
          dangerouslySetInnerHTML={{ __html: t('home.initial_instructions.subtitle') }}
        />
        <div
          className="initial-instructions--continue"
          role="button"
          onClick={() => {
            onSkip()
          }}
        >
          {t('home.initial_instructions.continue')}
        </div>
      </div>
    </div>
  )
}

export default InitialInstructions

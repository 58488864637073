import React, { useContext, useRef, useCallback, useEffect, useState } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'

import { useHistory, useParams, Redirect } from 'react-router-dom'
import './_Constellation.scss'

import { Canvas } from 'react-three-fiber'
import ConstallationGeometry from '../constallations/ConstallationGeometry'
import { CanvasContext, MainContext } from '../state/MainContext'
import {
  clearCurrentVideo,
  experienceIsFinished,
  setCurrentConstellation,
  setCurrentZodiac,
  setTextureData,
} from '../state/MainActions'

import Vimeo from '@u-wave/react-vimeo'
import { TextureLoader } from 'three'
import WhereToNext from '../constallations/WhereToNext'
import FinishedCover from '../constallations/FinishedCover'

const Constellation = () => {
  const { constellationIndex } = useParams()
  const [state, dispatch] = useContext(MainContext)
  const [canTransitionNext, setCanTransitionToNext] = useState(false)
  const [whereNext, showWhereNext] = useState(false)
  const [finishedCover, showFinishedCover] = useState(false)
  const [redirection, setRedirection] = useState('')
  const [videoPlayerClass, setVideoPlayerClass] = useState('')
  const [videoPreloading, setVideoPreloading] = useState(false)
  const history = useHistory()
  const refs = []
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })
  refs.push({ name: useRef(), sphere: useRef(), star: useRef() })

  const lines = []
  lines.push(useRef())
  lines.push(useRef())
  lines.push(useRef())
  lines.push(useRef())
  //https://gero3.github.io/facetype.js/
  /*const loader = new FontLoader().load('/assets/fonts/Open Sans_Regular.json', (font) => {
    textConfig.font = font
  })
  const textConfig = useMemo(
    () => ({ font: loader, size: 12, height: 2, steps: 12, curveSegments: 24 }),
    [loader]
  )*/

  const starTexture = new TextureLoader().load(`/assets/images/star-shine.png`)

  useEffect(() => {
    const index = parseInt(constellationIndex)
    if (state) {
      if (index === state.constellations.length) {
        // dispatch(experienceIsFinished(1))
        setTimeout(() => {
          dispatch(experienceIsFinished(2))
        }, 3000)

        setTimeout(() => {
          dispatch(experienceIsFinished(3))
          showFinishedCover(true)
        }, 10000)
      } else {
        dispatch(setCurrentConstellation(index))
        showWhereNext(false)
        setRedirection('')
      }
    }
  }, [constellationIndex])

  useEffect(() => {
    if (state.initialInstructions === 1) {
      setRedirection('/')
    } else {
      const t = []
      const v = []
      const smallVid = document.createElement('video')
      const mediumVid = document.createElement('video')
      if (!state.system.iOS) {
        for (let i = 0; i < 5; i++) {
          const vid = document.createElement('video')
          vid.key = `star-shine_${i}`
          vid.src = `/assets/videos/star-shine_${i}.mp4`
          vid.crossOrigin = 'Anonymous'
          vid.loop = true
          vid.play()
          v.push(vid)
        }

        smallVid.key = `star-shine_small`
        smallVid.src = `/assets/videos/star-shine_small.mp4`
        smallVid.crossOrigin = 'Anonymous'
        smallVid.loop = true
        smallVid.play()

        mediumVid.key = `star-shine_medium`
        mediumVid.src = `/assets/videos/star-shine_medium.mp4`
        mediumVid.crossOrigin = 'Anonymous'
        mediumVid.loop = true
        mediumVid.play()
      }

      state.textureData.map((txtr, index) => {
        if (txtr) {
          const zodiacTexture = new TextureLoader().load(`/assets/${txtr.image}`)
          const zodiacNameTexture = new TextureLoader().load(
            `/assets/${txtr.image.split('.').join('_name.')}`
          )
          t.push({
            id: txtr.id,
            image: txtr.image,
            visible: txtr.visible,
            text: txtr.text,
            ref: refs[index],
            zodiacTexture: zodiacTexture,
            zodiacNameTexture: zodiacNameTexture,
            starImage: starTexture,
            video: state.system.iOS
              ? null
              : txtr.id === 'ox' ||
                txtr.id === 'sheep' ||
                txtr.id === 'bladder' ||
                txtr.id === 'liver' ||
                txtr.id === 'tripple-burner'
              ? smallVid
              : txtr.id === 'kidney'
              ? mediumVid
              : v[Math.floor(Math.random() * 5)],
          })
        }
      })
      dispatch(setTextureData(t))
    }
  }, [])

  const onStarClicked = (id) => {
    const { zodiacs } = state.constellations[state.currentConstellation]
    zodiacs.map((zod, index) => {
      if (zod.starID === id) {
        dispatch(setCurrentZodiac(index))
      }
    })
    setVideoPreloading(true)
  }

  const onVideoEnd = () => {
    console.log('onVideoEnd', state.currentConstellation)
    const { zodiacs } = state.constellations[state.currentConstellation]
    setVideoPlayerClass('')
    dispatch(clearCurrentVideo())
    if (zodiacs.filter((zod) => zod.visited).length === zodiacs.length) {
      setCanTransitionToNext(true)
      if (state.currentConstellation > 0) {
        if (state.currentConstellation + 1 === state.constellations.length) {
          dispatch(experienceIsFinished(1))
        }
        setRedirection(`/constellation/${state.currentConstellation + 1}`)
      } else {
        showWhereNext(true)
      }
    }
  }

  const onVideoStart = () => {
    console.log('onVideoStart')
    setVideoPlayerClass('constellation--videoPlayer-idle')
    setVideoPreloading(false)
  }

  const onVideoProgress = (event) => {
    const { seconds, duration } = event
    if (duration - seconds < 0.51 && videoPlayerClass === 'constellation--videoPlayer-idle') {
      setVideoPlayerClass('constellation--videoPlayer-fadeout')
    }
  }

  const mouse = useRef([0, 0])
  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]),
    []
  )
  return (
    <>
      {state.currentConstellation >= 0 && (
        <>
          {redirection !== '' && <Redirect push to={redirection} />}
          {whereNext && <WhereToNext />}
          {finishedCover && <FinishedCover />}

          {state.currentVideo && state.currentVideo !== '' && (
            <>
              <div
                className="constellation--cover"
                style={{
                  backgroundColor:
                    videoPlayerClass === 'constellation--videoPlayer-idle'
                      ? 'rgba(0,0,0,1)'
                      : 'rgba(0,0,0,0)',
                }}
              >
                {videoPreloading && <img src="/assets/images/loading.gif" />}
              </div>
              <Vimeo
                className={`constellation--videoPlayer ${videoPlayerClass}`}
                style={{ width: '100vw', height: '100vh' }}
                video={state.currentVideo}
                controls={true}
                showTitle={false}
                showByline={false}
                autoplay={!state.system.iOS}
                width={'100%'}
                height={'100%'}
                responsive={true}
                onEnd={onVideoEnd}
                onReady={onVideoStart}
                onTimeUpdate={onVideoProgress}
              />
            </>
          )}
          <Canvas
            resize={{ polyfill: ResizeObserver }}
            camera={{ fov: 100, position: [0, 0, 0] }}
            style={{ backgroundColor: '#000000', width: '100vw', height: '100vh' }}
            onMouseMove={onMouseMove}
          >
            <CanvasContext.Provider value={[state, dispatch]}>
              {state.currentConstellation >= 0 && (
                <ConstallationGeometry
                  mouse={mouse}
                  history={history}
                  lineRefs={lines}
                  onStarClicked={onStarClicked}
                />
              )}
            </CanvasContext.Provider>
          </Canvas>
        </>
      )}
    </>
  )
}

export default Constellation

import React, { useContext, useEffect, useState } from 'react'
import './_ElementsCircle.scss'
import { Link, Redirect } from 'react-router-dom'
import { startCase } from 'lodash'
import { MainContext } from '../state/MainContext'
import Vimeo from '@u-wave/react-vimeo'

const ElementsCircle = () => {
  const [state] = useContext(MainContext)
  const [redirection, setRedirection] = useState('')
  const [centralYin, setCentralYin] = useState('elements-circle--image-hidden')
  const [centralYang, setCentralYang] = useState('elements-circle--image-hidden')
  const [element, setElement] = useState({
    fire: {
      yin: 'elements-circle--image-hidden',
      yang: 'elements-circle--image-hidden',
    },
    earth: {
      yin: 'elements-circle--image-hidden',
      yang: 'elements-circle--image-hidden',
    },
    wood: {
      yin: 'elements-circle--image-hidden',
      yang: 'elements-circle--image-hidden',
    },
    metal: {
      yin: 'elements-circle--image-hidden',
      yang: 'elements-circle--image-hidden',
    },
    water: {
      yin: 'elements-circle--image-hidden',
      yang: 'elements-circle--image-hidden',
    },
  })

  const obj = { key: 'asd', data: [] }
  state.yingYang.elements.map((y) => {
    obj.data.push({ key: y.key, visibility: 'none' })
  })
  const [elementState, setElementState] = useState(obj)

  return (
    <>
      {redirection !== '' && <Redirect push to={redirection} />}
      <div className="elements-circle--wrapper">
        <div className="elements-circle--rings">
          <img src="/assets/images/ying-yang/rings.png" alt="image rings" />
        </div>
        <div className="elements-circle--central-logo">
          <div className="elements-circle--central-logo-image-wrapper">
            <img
              src="/assets/images/ying-yang/central-logo.png"
              className="elements-circle--image-up"
              alt="Yin Yang central logo"
            />
            <img
              src="/assets/images/ying-yang/central-logo-yin.png"
              className={`elements-circle--image-yin ${centralYin}`}
              alt="Yin central logo"
            />
            <img
              src="/assets/images/ying-yang/central-logo-yang.png"
              className={`elements-circle--image-yang ${centralYang}`}
              alt="Yang central logo"
            />
          </div>
          <div
            className={`ying`}
            onClick={() => {
              setRedirection('/yin-yang/core/yin')
            }}
            onMouseOver={() => {
              setCentralYin('elements-circle--image-visible')
            }}
            onMouseOut={() => {
              setCentralYin('elements-circle--image-hidden')
            }}
          >
            <div className={`title ${centralYin}`}>Yin</div>
          </div>
          <div
            className={`yang`}
            onClick={() => {
              setRedirection('/yin-yang/core/yang')
            }}
            onMouseOver={() => {
              setCentralYang('elements-circle--image-visible')
            }}
            onMouseOut={() => {
              setCentralYang('elements-circle--image-hidden')
            }}
          >
            <div className={`title ${centralYang}`}>Yang</div>
          </div>
        </div>
        <div>
          <div>{}</div>
        </div>
        {elementState.data.map((yinyang) => {
          return (
            <div
              className={`elements-circle--central-${yinyang.key} elements-circle--element`}
              key={yinyang.key}
            >
              <div className="elements-circle--element-image-wrapper">
                <img
                  src={`/assets/images/ying-yang/${yinyang.key}.png`}
                  className="elements-circle--image-up"
                />
                <img
                  src={`/assets/images/ying-yang/${yinyang.key}-yin.png`}
                  className={`elements-circle--image-yin ${element[yinyang.key].yin}`}
                />
                <img
                  src={`/assets/images/ying-yang/${yinyang.key}-yang.png`}
                  className={`elements-circle--image-yang ${element[yinyang.key].yang}`}
                />
              </div>
              <div
                className="ying"
                onClick={() => {
                  setRedirection(`/yin-yang/${yinyang.key}/yin`)
                }}
                onMouseOver={() => {
                  const e = element
                  e[yinyang.key].yin = 'elements-circle--image-visible'
                  setElement({ ...e })
                }}
                onMouseOut={() => {
                  const e = element
                  e[yinyang.key].yin = 'elements-circle--image-hidden'
                  setElement({ ...e })
                }}
              >
                <div className={`title ${element[yinyang.key].yin}`}>
                  Yin
                  <br />
                  {startCase(yinyang.key)}
                </div>
              </div>
              <div
                className="yang"
                onClick={() => {
                  setRedirection(`/yin-yang/${yinyang.key}/yang`)
                }}
                onMouseOver={() => {
                  const e = element
                  e[yinyang.key].yang = 'elements-circle--image-visible'
                  setElement({ ...e })
                }}
                onMouseOut={() => {
                  const e = element
                  e[yinyang.key].yang = 'elements-circle--image-hidden'
                  setElement({ ...e })
                }}
              >
                <div className={`title ${element[yinyang.key].yang}`}>
                  Yang
                  <br />
                  {startCase(yinyang.key)}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ElementsCircle

import {
  GOTO_NEXT_CONSTELLATION,
  SET_CURRENT_CONSTELLATION,
  SET_CURRENT_ZODIAC,
  SKIP_INITIAL_INSTRUCTIONS,
  SET_CURRENT_VIDEO,
  SET_CURRENT_AUDIO,
  CLEAR_CURRENT_VIDEO,
  SET_TEXTURE_DATA,
  SET_ZODIAC_HOVER_STATE,
  SET_BIRTHDAY,
  RESET_STATE,
  ALLOW_ACCESS,
  SET_EXPERIENCE_FINISHED,
  SET_SYSTEM_SPECS,
  SET_SOUND_SRC,
} from './MainReducer'

export const setSoundSrc = (value) => ({
  type: SET_SOUND_SRC,
  payload: { src: value },
})

export const setSystemSpecs = (value) => ({
  type: SET_SYSTEM_SPECS,
  payload: { system: value },
})

export const experienceIsFinished = (value) => ({
  type: SET_EXPERIENCE_FINISHED,
  payload: { value: value },
})

export const allowAccess = () => ({
  type: ALLOW_ACCESS,
  payload: {},
})

export const resetState = () => ({
  type: RESET_STATE,
  payload: {},
})
export const skipInitialInstructions = () => ({
  type: SKIP_INITIAL_INSTRUCTIONS,
  payload: 0,
})

export const gotoNextConstellation = () => ({
  type: GOTO_NEXT_CONSTELLATION,
  payload: null,
})

export const setCurrentConstellation = (index) => ({
  type: SET_CURRENT_CONSTELLATION,
  payload: index,
})

export const setCurrentZodiac = (index) => ({
  type: SET_CURRENT_ZODIAC,
  payload: index,
})

export const setCurrentVideo = (vidID) => ({
  type: SET_CURRENT_VIDEO,
  payload: {
    videoID: vidID,
  },
})

export const setCurrentAudio = (vidURLs) => ({
  type: SET_CURRENT_AUDIO,
  payload: {
    audioURLS: vidURLs,
  },
})

export const clearCurrentVideo = () => ({
  type: CLEAR_CURRENT_VIDEO,
  payload: null,
})

export const setTextureData = (data) => ({
  type: SET_TEXTURE_DATA,
  payload: data,
})

export const updateZodiacHoverState = (constellationIndex, zodiacIndex, hovered) => ({
  type: SET_ZODIAC_HOVER_STATE,
  payload: {
    constIndex: constellationIndex,
    zdkIndex: zodiacIndex,
    hovered: hovered,
  },
})

export const setBirthday = (obj) => ({
  type: SET_BIRTHDAY,
  payload: obj,
})

/*
export const setCurrentZodiac = (index) => {
  console.log('useMainState.setCurrentZodiac')
  const tempState = { ...state }
  tempState.currentZodiac = index
  setState(tempState)
}

export const getCurrentZodiac = () => {
  console.log('useMainState.getCurrentZodiac')
  return state.constellations[state.currentConstellation].zodiacs[state.currentZodiac]
}

export const setZodiacVisited = (zodiac_index) => {
  console.log('useMainState.setZodiacVisited')
  const tempState = { ...state }
  tempState.constellations[tempState.currentConstellation].zodiacs[zodiac_index].visible = true
  tempState.constellations[tempState.currentConstellation].zodiacs[zodiac_index].visited = true
  setState(tempState)
}
export const setZodiacVisible = (zodiac_index) => {
  console.log('useMainState.setZodiacVisible')
  const tempState = { ...state }
  tempState.constellations[tempState.currentConstellation].zodiacs[zodiac_index].visible = true
  setState(tempState)
}

export const gotoNextZodiac = () => {
  console.log('useMainState.gotoNextZodiac')
  const tempState = { ...state }
  tempState.currentZodiac++
  setState(tempState)
}

export const hasMoreZodiacs = () => {
  console.log('useMainState.hasMoreZodiacs')
  return state.currentZodiac < state.constellations[state.currentConstellation].zodiacs.length - 1
}

export const gotoNextConstellation = () => {
  console.log('useMainState.gotoNextConstellation')
  const tempState = { ...state }
  tempState.currentConstellation = tempState.currentConstellation + 1
  tempState.currentZodiac = 0
  setState(tempState)
}
export const hasMoreConstellations = () => {
  console.log('useMainState.hasMoreConstellations')
  return state.currentConstellation < state.constellations.length - 1
}

export const getCurrentConstellation = () => {
  console.log('useMainState.getCurrentConstellation')
  return state.currentConstellation >= 0 ? state.constellations[state.currentConstellation] : null
}

export const setCurrentConstellation = (index) => {
  console.log('useMainState.setCurrentConstellation')
  const tempState = { ...state }
  tempState.currentConstellation = index
  tempState.currentZodiac = 0
  setState(tempState)
}

export const getConstellation = (index) => {
  console.log('useMainState.getConstellation')
  return state.constellations[index]
}

export const getTextures = () => {
  console.log('useMainState.getTextures')
  return state.textureData
}
*/

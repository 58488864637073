import React, { useContext } from 'react'
import { MainContext } from './components/state/MainContext'
import { Redirect, Route } from 'react-router-dom'
const PrivateRoute = ({ children, ...rest }) => {
  let [state] = useContext(MainContext)
  return (
    <Route
      {...rest}
      render={() =>
        state.isAuth && !state.isLocked ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute

import React, { useContext, useEffect, useState } from 'react'
import './_UserZodiac.scss'
import { Redirect, useParams } from 'react-router-dom'
import { MainContext } from '../state/MainContext'
import ZodiacDetails from '../ying-yang/ZodiacDetails'

const UserZodiac = () => {
  const [state] = useContext(MainContext)
  const { element, variation } = useParams()
  const [redirection, setRedirection] = useState('')

  useEffect(() => {
    if (state.initialInstructions === 1) {
      setRedirection('/')
    }
  }, [])
  return (
    <>
      {redirection !== '' && <Redirect push to={redirection} />}
      <div className="zodiac-details--container">
        <ZodiacDetails />
      </div>
    </>
  )
}

export default UserZodiac

import React, { useContext, useState } from 'react'

import './_ElementDetails.scss'
import { MainContext } from '../state/MainContext'
import { Link, Redirect } from 'react-router-dom'
import { startCase } from 'lodash'
import BirthdayInput from '../home/BirthdayInput'
import { setBirthday } from '../state/MainActions'

const ElementDetails = ({ element, variation }) => {
  const [state, dispatch] = useContext(MainContext)
  const [currentElement] = state.yingYang.elements.filter((el) => el.key === element)
  const [redirection, setRedirection] = useState('')
  const [birthdayPopup, showBirthdayPopup] = useState(false)

  return (
    <>
      {redirection !== '' && <Redirect push to={redirection} />}
      <div className="element-details--wrapper">
        <div className="element-details--icon">
          <img
            src={`/assets/images/ying-yang/${currentElement.key}-large.png`}
            alt={currentElement.details.title}
          />
        </div>
        <div className="element-details--listing">
          <div>
            <div className="element-details--title-wrapper">
              <div className="element-details--title">{currentElement.details.title}</div>
              <div className="element-details--close">
                <Link to="/yin-yang">
                  <img src={`/assets/images/ying-yang/close.png`} alt="close" />
                </Link>
              </div>
            </div>
            <div className="element-details--specs">
              <div className="element-details--spec-column">
                <div className="element-details--spec-line">
                  <div>Direction:</div> <div>{currentElement.details.direction}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Season:</div> <div>{currentElement.details.season}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Climate:</div> <div> {currentElement.details.climate}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Colour: </div> <div>{currentElement.details.color}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Shape:</div> <div> {currentElement.details.shape}</div>
                </div>
              </div>
              <div className="element-details--spec-column">
                <div className="element-details--spec-line">
                  <div>Planet:</div> <div> {currentElement.details.planet}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Heavenly Creature:</div>{' '}
                  <div> {currentElement.details.heavenlyCreature}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Heavenly Stems:</div> <div> {currentElement.details.heavenlyStems}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Taste:</div> <div> {currentElement.details.taste}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Sensory Organs:</div> <div> {currentElement.details.sensoryOrgans}</div>
                </div>
              </div>
              <div className="element-details--spec-column">
                <div className="element-details--spec-line">
                  <div>Spiritual dimension:</div>{' '}
                  <div> {currentElement.details.spiritualDimensions}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Beneficial emotions: </div>{' '}
                  <div>{currentElement.details.beneficialEmotions}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Damaging emotions: </div>{' '}
                  <div>{currentElement.details.damagingEmotions}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Note: </div> <div>{currentElement.details.note}</div>
                </div>
                <div className="element-details--spec-line">
                  <div>Tone: </div> <div>{currentElement.details.tone}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className="element-details--description"
              dangerouslySetInnerHTML={{ __html: currentElement.details.description }}
            ></div>
            <div className="element-details--signs-title">Fixed Element: Chinese Zodiac Sign</div>
            <div className="element-details--footer">
              <div className="element-details--signs-wrapper">
                {currentElement.details.signs.map((sign) => {
                  return (
                    <div className="element-details--sign-column">
                      <img src={`/assets/images/stars/${sign.key}.png`} />
                      <div>{sign.key.toUpperCase()}</div>
                      <div>({sign.variation.toUpperCase()})</div>
                    </div>
                  )
                })}
              </div>
              <div
                className="element-details--sign-button"
                onClick={() => {
                  if (state.birthday !== '') {
                    setRedirection('/yin-yang/your-zodiac')
                  } else {
                    showBirthdayPopup(true)
                  }
                }}
              >
                <div>Find out more about your fixed element</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {birthdayPopup && (
        <div className="element-details--birthday-input">
          <div className="element-details--birthday-input-wrapper">
            <div className="element-details--close">
              <div
                onClick={() => {
                  showBirthdayPopup(false)
                }}
              >
                <img src={`/assets/images/ying-yang/close.png`} />
              </div>
            </div>
            <h1>It appears you have not entered your birthdate...</h1>
            <p>
              Entering your birthdate will provide you with a customized experience, with special
              info tailored to your specific Chinese Zodiac.
            </p>
            <BirthdayInput
              showSkip={false}
              onSubmit={(data) => {
                dispatch(setBirthday(data))
                setRedirection(`/yin-yang/your-zodiac`)
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ElementDetails

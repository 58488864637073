import React, { useContext, useState, useMemo, useRef, useEffect } from 'react'
import { extend, useFrame } from 'react-three-fiber'
import * as THREE from 'three'
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline'

import { uniqueId } from 'lodash'

import './_Dodecahedron.scss'
import { TextureLoader } from 'three'
import { CanvasContext } from '../state/MainContext'

extend({ MeshLine, MeshLineMaterial })

const Dodecahedron = ({ textures, lineRefs, onStarClicked }) => {
  const currentConstLines = useRef()
  const previousConstLines = useRef()
  const spheres = useRef()
  // const reflow = useReflow()
  const [state] = useContext(CanvasContext)
  // const { zodiacs } = state.constellations[state.currentConstellation]
  const [stars, setStars] = useState(textures)
  const [starAlpha, setStarAlpha] = useState(1)

  useEffect(() => {
    if (state.experienceFinished === 1) {
      setStarAlpha(0)
    } else if (state.experienceFinished === 2) {
      setStarAlpha(0)
    } else if (state.experienceFinished === 3) {
      setStarAlpha(1)
    }
  }, [state.experienceFinished])

  const generateLineData = (index) => {
    const linePoints = []
    const { lines } = state.constellations[index]
    for (let i = 0; i < lines.length; i++) {
      const [star] = stars.filter((st) => st.id === lines[i].starID)
      if (!!star) {
        linePoints.push(new THREE.Vector3(star.circleX, star.circleY, star.circleZ))
      }
    }

    return linePoints
  }

  const previousPoints = useMemo(() => {
    const olderPoints = []
    for (let i = 0; i < state.constellations.length; i++) {
      if (state.experienceFinished === 0) {
        if (i < state.currentConstellation) {
          olderPoints.push(generateLineData(i))
        }
      } else {
        olderPoints.push(generateLineData(i))
      }
    }
    return olderPoints
  }, [state.currentVideo, state.currentConstellation, state.experienceFinished])

  const points = useMemo(() => {
    return state.experienceFinished === 0 ? generateLineData(state.currentConstellation) : []
  }, [state.currentVideo, state.currentConstellation])

  // const onDrawLines = useCallback((self) => self.setVertices(points), [points])

  useFrame(({ clock }) => {
    /*if (currentConstLines.current && currentConstLines.current.opacity < 1) {
      currentConstLines.current.opacity += 0.01
    }*/
    if (state.experienceFinished === 1) {
      for (let i = 0; i < textures.length; i++) {
        if (textures[i].ref.star && textures[i].ref.star.current) {
          if (textures[i].ref.star.current.opacity > 0) {
            textures[i].ref.star.current.opacity -= 0.05
          }
        }
      }
    } else if (state.experienceFinished === 2) {
      for (let i = 0; i < textures.length; i++) {
        if (textures[i].ref.star && textures[i].ref.star.current) {
          if (textures[i].ref.star.current.opacity < 1) {
            textures[i].ref.star.current.opacity += 0.01
          }
        }
      }
    } else if (state.experienceFinished === 3) {
      for (let i = 0; i < textures.length; i++) {
        if (textures[i].ref.star && textures[i].ref.star.current) {
          if (textures[i].ref.star.current.opacity > 0.3) {
            textures[i].ref.star.current.opacity -= 0.01
          }
        }
        if (textures[i].ref.sphere && textures[i].ref.sphere.current) {
          if (textures[i].ref.sphere.current.opacity > 0.3) {
            textures[i].ref.sphere.current.opacity -= 0.01
          }
        }
      }
      for (let i = 0; i < lineRefs.length; i++) {
        if (lineRefs[i].current) {
          if (lineRefs[i].current.opacity > 0.3) {
            lineRefs[i].current.opacity -= 0.01
          }
        }
      }
    }
  })
  return (
    <group>
      {previousPoints.length > 0 &&
        state.constellations.map((c, index) => {
          return (
            <mesh raycast={MeshLineRaycast} key={uniqueId('olderLineGroup')}>
              <meshLine attach="geometry" points={previousPoints[index]} />
              <meshLineMaterial
                ref={lineRefs[index]}
                attach="material"
                color={c.color}
                lineWidth={3}
                depthTest={false}
                blending={THREE.NormalBlending}
                transparent={true}
                opacity={0.8}
              />
            </mesh>
          )
        })}
      {points.length > 0 && (
        <mesh raycast={MeshLineRaycast}>
          <meshLine attach="geometry" points={points} />
          <meshLineMaterial
            ref={currentConstLines}
            attach="material"
            color={'#a5a5a5'}
            opacity={1}
            sizeAttenuation={true}
            lineWidth={3}
            depthTest={false}
            blending={THREE.NormalBlending}
            transparent={true}
            useMap={false}
            useAlpha={false}
          />
        </mesh>
      )}
      {stars.map((entry, index) => {
        let zodiac = null
        let zodiacIndex = -1
        let constellationIndex = -1
        for (let i = 0; i <= state.currentConstellation; i++) {
          const constellation = state.constellations[i]
          for (let j = 0; j < constellation.zodiacs.length; j++) {
            if (constellation.zodiacs[j].starID === entry.id) {
              zodiac = constellation.zodiacs[j]
              zodiacIndex = j
              constellationIndex = i
            }
          }
        }
        return (
          <group key={uniqueId('starGroup')}>
            <mesh
              onPointerOver={(e) => {
                if (
                  state.experienceFinished === 0 &&
                  e.eventObject &&
                  e.eventObject.material &&
                  e.eventObject.material.visible
                ) {
                  document.getElementsByTagName('body')[0].style.cursor = 'pointer'
                  e.eventObject.material.opacity = 1
                }
              }}
              onPointerOut={(e) => {
                if (
                  state.experienceFinished === 0 &&
                  e.eventObject &&
                  e.eventObject.material &&
                  e.eventObject.material.visible
                ) {
                  document.getElementsByTagName('body')[0].style.cursor = 'auto'
                  e.eventObject.material.opacity = 0
                }
              }}
              uuid={`mesh_name_${constellationIndex}_${zodiacIndex}`}
              position={[entry.x, entry.y, entry.z + 0.1]}
              key={uniqueId('starElement')}
              onClick={() => {
                if (zodiac && zodiac.visible && state.experienceFinished === 0) {
                  onStarClicked(entry.id)
                }
              }}
            >
              <planeBufferGeometry attach="geometry" args={[150, 150]} />
              <meshStandardMaterial
                attach="material"
                visible={
                  !!zodiac && zodiac.visible && constellationIndex === state.currentConstellation
                }
                side={THREE.DoubleSide}
                map={!!zodiac && zodiac.visible ? entry.zodiacNameTexture : null}
                transparent={true}
                opacity={0}
              />
            </mesh>
            <mesh
              uuid={`mesh_${constellationIndex}_${zodiacIndex}`}
              position={[entry.x, entry.y, entry.z]}
              key={uniqueId('starElement')}
            >
              <planeBufferGeometry attach="geometry" args={[150, 150]} />
              <meshStandardMaterial
                attach="material"
                color="white"
                visible={
                  state.experienceFinished > 0 ||
                  (state.experienceFinished === 0 &&
                    !!zodiac &&
                    zodiac.visible &&
                    constellationIndex === state.currentConstellation)
                }
                side={THREE.DoubleSide}
                map={
                  !!zodiac && zodiac.visited
                    ? entry.zodiacTexture
                    : !entry.video
                    ? entry.starImage
                    : null
                }
                transparent={true}
                opacity={starAlpha}
                ref={entry.ref.star}
              >
                {!!zodiac && !zodiac.visited && entry.video && (
                  <videoTexture attach="map" args={[entry.video]} />
                )}
              </meshStandardMaterial>
            </mesh>
            <mesh
              position={[entry.circleX, entry.circleY, entry.circleZ]}
              key={uniqueId('starSphere')}
            >
              <sphereGeometry attach="geometry" args={[5, 16, 16]} />
              <meshStandardMaterial
                ref={entry.ref.sphere}
                attach="material"
                color={'white'}
                visible={!!zodiac && zodiac.visited}
                side={THREE.DoubleSide}
                transparent={true}
              />
            </mesh>
            {/*!!zodiac && (
              <mesh position={[entry.x, entry.y - 50, entry.z]} key={uniqueId('starName')}>
                <textBufferGeometry attach="geometry" args={[zodiac.title, textConfig]} />
                <meshStandardMaterial
                  attach="material"
                  color={'white'}
                  visible={
                    !!zodiac && zodiac.visible && constellationIndex === state.currentConstellation
                  }
                  side={THREE.DoubleSide}
                />
              </mesh>
            )*/}
          </group>
        )
      })}
    </group>
  )
}

export default Dodecahedron

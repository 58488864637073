import { padStart } from 'lodash'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { defaultMainState } from './MainContext'

export const RESET_STATE = 'RESET_STATE'
export const SKIP_INITIAL_INSTRUCTIONS = 'SKIP_INITIAL_INSTRUCTIONS'
export const SET_BIRTHDAY = 'SET_BIRTHDAY'
export const GOTO_NEXT_CONSTELLATION = 'GOTO_NEXT_CONSTELLATION'
export const SET_CURRENT_CONSTELLATION = 'SET_CURRENT_CONSTELLATION'
export const SET_CURRENT_VIDEO = 'SET_CURRENT_VIDEO'
export const SET_CURRENT_AUDIO = 'SET_CURRENT_AUDIO'
export const CLEAR_CURRENT_VIDEO = 'CLEAR_CURRENT_VIDEO'
export const SET_CURRENT_ZODIAC = 'SET_CURRENT_ZODIAC'
export const SET_TEXTURE_DATA = 'SET_TEXTURE_DATA'
export const SET_ZODIAC_HOVER_STATE = 'SET_ZODIAC_HOVER_STATE'
export const ALLOW_ACCESS = 'ALLOW_ACCESS'
export const SET_EXPERIENCE_FINISHED = 'SET_EXPERIENCE_FINISHED'
export const SET_SYSTEM_SPECS = 'SET_SYSTEM_SPECS'
export const SET_SOUND_SRC = 'SET_SOUND_SRC'

export const mainReducer = (state, action) => {
  let newState = {
    ...state,
  }
  switch (action.type) {
    case SET_SOUND_SRC:
      newState.audioSrc = action.payload.src
      return newState

    case SET_SYSTEM_SPECS:
      newState.system = action.payload.system
      return newState

    case SET_EXPERIENCE_FINISHED:
      newState.experienceFinished = action.payload.value
      return newState

    case ALLOW_ACCESS:
      newState.isAuth = true
      return newState

    case RESET_STATE:
      const { isAuth, system } = newState
      newState = cloneDeep(defaultMainState)
      newState.isAuth = isAuth
      newState.system = system
      return newState

    case SET_BIRTHDAY:
      const { birthdayDay, birthdayMonth, birthdayYear } = action.payload
      newState.birthday = `${birthdayYear}-${padStart(birthdayMonth, 2, '0')}-${padStart(
        birthdayDay,
        2,
        '0'
      )}`
      console.log(newState)
      return newState
    case SKIP_INITIAL_INSTRUCTIONS:
      newState.initialInstructions = 0
      return newState
    case GOTO_NEXT_CONSTELLATION:
      newState.currentConstellation = newState.currentConstellation + 1
      newState.currentZodiac = 0
      newState.constellations[newState.currentConstellation].zodiacs[
        newState.currentZodiac
      ].visible = true
      return newState
    case SET_CURRENT_CONSTELLATION:
      newState.currentConstellation = action.payload
      newState.currentZodiac = 0
      newState.constellations[newState.currentConstellation].zodiacs[
        newState.currentZodiac
      ].visible = true
      return newState
    case SET_CURRENT_ZODIAC:
      const index = action.payload
      const zodiac = newState.constellations[newState.currentConstellation].zodiacs[index]
      newState.currentZodiac = index
      if (zodiac.starID === 'dragon') {
        if (newState.birthday !== '') {
          let z = null
          for (let i = 0; i < newState.birthdaySigns.length; i++) {
            if (
              moment(newState.birthday).isBetween(
                newState.birthdaySigns[i].from,
                newState.birthdaySigns[i].to
              )
            ) {
              z = newState.zodiacSigns.filter(
                (sign) => sign.key === newState.birthdaySigns[i].key
              )[0]
              break
            }
          }
          if (z) {
            const { elements, yinYang } = z
            const el = newState.yingYang.elements.filter((el) => el.key === elements.toLowerCase())
            newState.currentVideo =
              el.length > 0
                ? el[0][yinYang.toLowerCase()]
                : newState.yingYang.elements[Math.floor(Math.random() * 5)][yinYang.toLowerCase()]
          } else {
            newState.currentVideo = newState.yingYang.elements[Math.floor(Math.random() * 5)].yin
          }
        } else {
          console.log(newState.yingYang.elements[Math.floor(Math.random() * 5)])
          newState.currentVideo = newState.yingYang.elements[Math.floor(Math.random() * 5)].yin
        }
      } else {
        newState.currentVideo = zodiac.video
      }

      registerClickedStar(newState, index)
      return newState
    case SET_CURRENT_VIDEO:
      state.currentAudio = action.payload
      return newState
    case SET_TEXTURE_DATA:
      newState.textureData = action.payload
      return newState
    case CLEAR_CURRENT_VIDEO:
      newState.currentVideo = ''
      const { zodiacs } = newState.constellations[newState.currentConstellation]
      zodiacs.forEach((zod, index) => {
        if (index === newState.currentZodiac) {
          setZodiacVisited(newState, zodiacs, index)
          if (index > 0 && zodiacs[index - 1].autoPlayNext) {
            zodiacs[index - 1].visible = true
            zodiacs[index - 1].visited = true
          }
        }
      })
      return newState
    case SET_ZODIAC_HOVER_STATE:
      console.log('SET_ZODIAC_HOVER_STATE', action)
      newState.constellations[action.payload.constIndex].zodiacs[action.payload.zdkIndex].hovered =
        action.payload.hovered
      return newState
    default:
      return state
  }
}

const registerClickedStar = (newState, index) => {
  const zodiacs = newState.constellations[newState.currentConstellation].zodiacs
  const zodiac = zodiacs[index]
  const [star] = newState.textureData.filter((str) => str.id === zodiac.starID)

  if (
    newState.constellations[newState.currentConstellation].lines.length === 0 &&
    newState.currentConstellation > 0
  ) {
    newState.constellations[newState.currentConstellation - 1].lines.push({
      starID: zodiac.starID,
      x: star.x,
      y: star.y,
      z: star.z,
    })
  }

  const lines = newState.constellations[newState.currentConstellation].lines.filter(
    (line) => line.starID === zodiac.starID
  )
  if (lines.length === 0) {
    newState.constellations[newState.currentConstellation].lines.push({
      starID: zodiac.starID,
      x: star.x,
      y: star.y,
      z: star.z,
    })
  }
  if (zodiac.autoPlayNext && index < zodiacs.length - 1) {
    registerClickedStar(newState, index + 1)
  }
}

const setZodiacVisited = (newState, zodiacs, index) => {
  zodiacs[index].visited = true
  // console.log(newState.constellations[newState.currentConstellation], index)
  /*if (
    newState.constellations[newState.currentConstellation].type === 'half-uber' &&
    index === newState.constellations[newState.currentConstellation].breakIndex - 1
  ) {
    for (let i = newState.currentZodiac; i < zodiacs.length; i++) {
      zodiacs[i].visible = true
    }
  } else {*/
  if (index < zodiacs.length - 1) {
    zodiacs[index + 1].visible = true
    if (zodiacs[index].autoPlayNext) {
      setZodiacVisited(newState, zodiacs, index + 1)
    }
  }
  /*}*/
}

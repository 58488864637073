import React, { useContext, useState } from 'react'

import './_WhereToNext.scss'
import { gotoNextConstellation, resetState, setSoundSrc } from '../state/MainActions'
import { MainContext } from '../state/MainContext'
import { NavLink, Redirect } from 'react-router-dom'
const WhereToNext = () => {
  const [state, dispatch] = useContext(MainContext)
  const [redirection, setRedirection] = useState('')

  return (
    <>
      {redirection !== '' && <Redirect push to={redirection} />}

      <div className="whereToNext">
        <h1 className="whereToNext--title">Where To Next</h1>
        <div className="whereToNext--links">
          <NavLink
            to={`/constellation/${state.currentConstellation + 1}`}
            onClick={() => {
              dispatch(
                setSoundSrc(
                  `/assets/audio/constellation_${state.currentConstellation}${
                    state.system.iOS ? '_ios' : ''
                  }.aac`
                )
              )
            }}
          >
            Continue to the next constellation
          </NavLink>
        </div>
        <div
          className="whereToNext--links"
          onClick={() => {
            dispatch(setSoundSrc(`/assets/audio/yin-yang${state.system.iOS ? '_ios' : ''}.aac`))
            dispatch(gotoNextConstellation())
            setRedirection('/yin-yang')
          }}
        >
          Check out the Yin/Yang legend
        </div>
        <div
          className="whereToNext--links"
          onClick={() => {
            dispatch(setSoundSrc(`/assets/audio/restaurant${state.system.iOS ? '_ios' : ''}.aac`))
            dispatch(resetState())
            setRedirection('/home')
          }}
        >
          Start over
        </div>
      </div>
    </>
  )
}

export default WhereToNext

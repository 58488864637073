import React, { useContext, useState } from 'react'
import './_Home.scss'
import { Redirect } from 'react-router-dom'
import { MainContext } from '../state/MainContext'
import Placemat from '../home/Placemat'
import BirthdayInput from '../home/BirthdayInput'
import InitialInstructions from '../home/InitialInstructions'
import SkipBirthday from '../home/SkipBirthday'
import { setBirthday, setSoundSrc, skipInitialInstructions } from '../state/MainActions'

const Home = () => {
  const [state, dispatch] = useContext(MainContext)
  const [isBirthdySkipped, setBirthdaySkipped] = useState(false)
  const [redirection, setRedirection] = useState('')

  return (
    <div className="home">
      {redirection !== '' && <Redirect push to={redirection} />}
      <div className="home--placemat">
        <Placemat />
      </div>
      <div className="home--birthday-input">
        <BirthdayInput
          showSkip={true}
          onSubmit={(data) => {
            dispatch(
              setSoundSrc(
                `/assets/audio/constellation_${state.currentConstellation}${
                  state.system.iOS ? '_ios' : ''
                }.aac`
              )
            )
            dispatch(setBirthday(data))
            setRedirection(`/constellation/${state.currentConstellation}`)
          }}
          onSkip={() => {
            setBirthdaySkipped(true)
          }}
        />
      </div>
      {isBirthdySkipped && (
        <div className="home--warn-birthday-skipped">
          <SkipBirthday
            onSkip={() => {
              // dispatch(gotoNextConstellation())
              dispatch(
                setSoundSrc(
                  `/assets/audio/constellation_${state.currentConstellation}${
                    state.system.iOS ? '_ios' : ''
                  }.aac`
                )
              )
              setRedirection(`/constellation/${state.currentConstellation}`)
            }}
            onCancel={() => {
              setBirthdaySkipped(false)
            }}
          />
        </div>
      )}

      {state.initialInstructions === 1 && (
        <div className="home--initial-instructions">
          <InitialInstructions
            onSkip={() => {
              dispatch(setSoundSrc(`/assets/audio/restaurant${state.system.iOS ? '_ios' : ''}.aac`))
              dispatch(skipInitialInstructions())
              setRedirection('/home/start')
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Home

import React from 'react'
import './_Background.scss'

const Background = ({ children }) => {
  return (
    <div className="background--wrapper">
      <div className="background--image">
        <img src="/assets/images/commongBG.png" alt="Fire Horse & Shadow BG" />>
      </div>
      <div className="background--content">{children}</div>
    </div>
  )
}

export default Background

import React, { useEffect, useState, useContext, useRef } from 'react'
import { useFrame, useThree } from 'react-three-fiber'

import './_ConstallationGeometry.scss'
import StarryBackground from './StarryBackground'
import Dodecahedron from './Dodecahedron'
import { CanvasContext } from '../state/MainContext'
import { setTextureData } from '../state/MainActions'
import * as THREE from 'three'

const ConstallationGeometry = ({ mouse, lineRefs, onStarClicked }) => {
  const { camera } = useThree()
  const [state, dispatch] = useContext(CanvasContext)
  const [data, setData] = useState([])

  const leftTorus = useRef()
  const rightTorus = useRef()

  const numPoints = 12
  const angle = (2.0 * Math.PI) / numPoints
  const radius = 500
  const smallRadius = 420
  const center = { x: -200, y: 0 }

  useEffect(() => {
    if (data.length === 0) {
      const t = []
      const initialAngle = (2.0 * Math.PI * 4) / 12
      state.textureData.map((txtr, index) => {
        const i = index % 12
        const circleIndex = Math.floor(index / 12)

        t.push({
          id: txtr.id,
          image: txtr.image,
          visible: txtr.visible,
          text: txtr.text,
          ref: txtr.ref,
          zodiacTexture: txtr.zodiacTexture,
          zodiacNameTexture: txtr.zodiacNameTexture,
          starImage: txtr.starImage,
          video: txtr.video,
          x:
            Math.cos(initialAngle + i * angle) * radius + center.x + (circleIndex * 700 + center.x),
          y: Math.sin(initialAngle + i * angle) * radius + center.y,
          z: -500,
          circleX:
            Math.cos(initialAngle + i * angle) * smallRadius +
            center.x +
            (circleIndex * 700 + center.x),
          circleY: Math.sin(initialAngle + i * angle) * smallRadius + center.y,
          circleZ: -500,
        })
      })
      setData(t)
      dispatch(setTextureData(t))
    }
  }, [])

  useFrame(() => {
    camera.position.x += (mouse.current[0] / 50 - camera.position.x) * 0.1
    camera.position.y += (-mouse.current[1] / 50 - camera.position.y) * 0.1

    if (state.experienceFinished === 2) {
      if (leftTorus.current && leftTorus.current.opacity < 1) {
        leftTorus.current.opacity += 0.01
      }
      if (rightTorus.current && rightTorus.current.opacity < 1) {
        rightTorus.current.opacity += 0.01
      }
    } else if (state.experienceFinished === 3) {
      if (leftTorus.current && leftTorus.current.opacity > 0.3) {
        leftTorus.current.opacity -= 0.01
      }
      if (rightTorus.current && rightTorus.current.opacity > 0.3) {
        rightTorus.current.opacity -= 0.01
      }
    }
  })
  return (
    <>
      <ambientLight />
      <StarryBackground />
      <mesh position={[0, 0, -500]}>
        <planeBufferGeometry attach="geometry" args={[2500, 1500]} />
        <meshStandardMaterial
          attach="material"
          color={'white'}
          side={THREE.DoubleSide}
          transparent={true}
          opacity={0}
        />
      </mesh>
      {data.length > 0 && (
        <>
          <Dodecahedron
            textures={data}
            lineRefs={lineRefs}
            circleIndex={0}
            onStarClicked={onStarClicked}
          />
        </>
      )}
      <mesh position={[center.x - 200, center.y, -500]}>
        <torusBufferGeometry args={[smallRadius, 1, 24, 100]} />
        <meshStandardMaterial
          ref={leftTorus}
          attach="material"
          color={'white'}
          side={THREE.DoubleSide}
          transparent={true}
          opacity={0}
          visible={state.experienceFinished > 0}
        />
      </mesh>
      <mesh position={[center.x + 500, center.y, -500]}>
        <torusBufferGeometry args={[smallRadius, 1, 24, 100]} />
        <meshStandardMaterial
          ref={rightTorus}
          attach="material"
          color={'white'}
          side={THREE.DoubleSide}
          transparent={true}
          opacity={0}
          visible={state.experienceFinished > 0}
        />
      </mesh>
    </>
  )
}

export default ConstallationGeometry

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import './_BirthdayInput.scss'

const schema = yup.object().shape({
  birthdayDay: yup.number('Please enter a valid day').min(1).max(31).defined(),
  birthdayMonth: yup.number('Please enter a valid month').min(1).max(12).defined(),
  birthdayYear: yup
    .number('Please enter a valid year')
    .min(1920)
    .max(new Date().getFullYear())
    .defined(),
})
const BirthdayInput = ({ showSkip, onSubmit, onSkip }) => {
  const { t } = useTranslation()
  const [state, setState] = useState({ birthdayDay: '', birthdayMonth: '', birthdayYear: '' })
  const [error, setError] = useState({ birthdayDay: '', birthdayMonth: '', birthdayYear: '' })

  const extractError = (arr, key) => {
    const entry = arr.filter((er) => er.path === key)
    return entry.length > 0 ? entry[0].message : ''
  }

  const validate = () => {
    schema
      .validate(
        {
          birthdayDay: state.birthdayDay,
          birthdayMonth: state.birthdayMonth,
          birthdayYear: state.birthdayYear,
        },
        { abortEarly: false }
      )
      .then((valid) => {
        setError({
          birthdayDay: '',
          birthdayMonth: '',
          birthdayYear: '',
        })
        onSubmit(state)
      })
      .catch((err) => {
        setError({
          birthdayDay: extractError(err.inner, 'birthdayDay'),
          birthdayMonth: extractError(err.inner, 'birthdayMonth'),
          birthdayYear: extractError(err.inner, 'birthdayYear'),
        })
      })
  }
  return (
    <div className="birthday-input">
      <div className="birthday-input--container">
        <div className="birthday-input--title">{t('home.birthday_input.title')}</div>
        <div className="birthday-input--form-container">
          <div className="birthday-input--container-box">
            <input
              className={`birthday-input--input ${
                error.birthdayDay !== ''
                  ? 'birthday-input--input-error'
                  : 'birthday-input--input-prestine'
              }`}
              name="birthdayDay"
              value={state.birthdayDay}
              onChange={(e) => {
                setState({ ...state, birthdayDay: e.target.value })
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  validate()
                }
              }}
            />
            <div className="birthday-input--legend">{t('home.birthday_input.day_subtitle')}</div>
          </div>
          <div className="birthday-input--container-box">
            <input
              className={`birthday-input--input ${
                error.birthdayMonth !== ''
                  ? 'birthday-input--input-error'
                  : 'birthday-input--input-prestine'
              }`}
              name="birthdayMonth"
              value={state.birthdayMonth}
              onChange={(e) => {
                setState({ ...state, birthdayMonth: e.target.value })
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  validate()
                }
              }}
            />
            <div className="birthday-input--legend">{t('home.birthday_input.month_subtitle')}</div>
          </div>
          <div className="birthday-input--container-box">
            <input
              className={`birthday-input--input ${
                error.birthdayYear !== ''
                  ? 'birthday-input--input-error'
                  : 'birthday-input--input-prestine'
              }`}
              name="birthdayYear"
              value={state.birthdayYear}
              onChange={(e) => {
                setState({ ...state, birthdayYear: e.target.value })
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  validate()
                }
              }}
            />
            <div className="birthday-input--legend">{t('home.birthday_input.year_subtitle')}</div>
          </div>
          <div className="birthday-input--container-box">
            <div
              onClick={() => {
                validate()
              }}
              className="birthday-input--enter"
            >
              {t('home.birthday_input.enter')}
            </div>
          </div>
        </div>
        {showSkip && (
          <div
            role="button"
            className="birthday-input--skip"
            onClick={() => {
              onSkip()
            }}
          >
            {t('home.birthday_input.skip')}
          </div>
        )}
      </div>
    </div>
  )
}

export default BirthdayInput

import React from 'react'

export const defaultMainState = {
  system: {
    iOS: null,
    isSafari: null,
  },
  audioSrc: '',
  experienceFinished: 0,
  isAuth: true,
  birthday: '',
  initialInstructions: 1,
  currentConstellation: 0,
  currentZodiac: -1,
  currentVideo: null,
  currentAudio: null,
  constellations: [
    {
      type: 'regular',
      breakIndex: -1,
      color: '#07FEFE',
      lines: [],
      sound: ['constellation_0.acc', 'constellation_0.ogg'],
      zodiacs: [
        {
          title: 'Pig',
          video: process.env.NODE_ENV === 'production' ? '497335471' : '299444581',
          autoPlayNext: true,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'pig',
        },
        {
          title: 'Rooster',
          video: process.env.NODE_ENV === 'production' ? '497335471' : '237691721',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'rooster',
        },
        {
          title: 'Horse',
          video: process.env.NODE_ENV === 'production' ? '497344432' : '231246506',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'horse',
        },
        {
          title: 'Rabbit',
          video: process.env.NODE_ENV === 'production' ? '497345693' : '409759581',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'rabbit',
        },
        {
          title: 'Dragon',
          video: process.env.NODE_ENV === 'production' ? 'XXXX' : '323804305',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'dragon',
        },
      ],
    },
    {
      type: 'uber',
      breakIndex: -1,
      color: '#ffffff',
      lines: [],
      sound: ['constellation_1.acc', 'constellation_1.ogg'],
      zodiacs: [
        {
          title: 'Snake',
          video: process.env.NODE_ENV === 'production' ? '497749204' : '169900085',
          autoPlayNext: false,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'snake',
        },
        {
          title: 'Kidney',
          video: process.env.NODE_ENV === 'production' ? '497748012' : '389051037',
          autoPlayNext: true,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'kidney',
        },
        {
          title: 'Ox',
          video: process.env.NODE_ENV === 'production' ? '497748012' : '389051037',
          autoPlayNext: false,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'ox',
        },

        {
          title: 'Bladder',
          video: process.env.NODE_ENV === 'production' ? '497748617' : '358039097',
          autoPlayNext: true,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'bladder',
        },
        {
          title: 'Sheep',
          video: process.env.NODE_ENV === 'production' ? '497748617' : '358039097',
          autoPlayNext: false,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'sheep',
        },
        {
          title: 'Monkey',
          video: process.env.NODE_ENV === 'production' ? '497748951' : '355495764',
          autoPlayNext: false,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'monkey',
        },
        {
          title: 'Triple Burner',
          video: process.env.NODE_ENV === 'production' ? '497784903' : '155431288',
          autoPlayNext: false,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'tripple-burner',
        },
        {
          title: 'Liver',
          video: process.env.NODE_ENV === 'production' ? '497749674' : '321485129',
          autoPlayNext: false,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'liver',
        },
        {
          title: 'Dog',
          video: process.env.NODE_ENV === 'production' ? '508509589' : '253763048',
          autoPlayNext: false,
          visited: false,
          visible: true,
          hovered: false,
          starID: 'dog',
        },
      ],
    },
    {
      type: 'regular',
      breakIndex: -1,
      color: '#FF69B4',
      lines: [],
      sound: ['constellation_2.aac', 'constellation_2.ogg'],
      zodiacs: [
        {
          title: 'Small Intestine',
          video: process.env.NODE_ENV === 'production' ? '497759738' : '389051037',
          autoPlayNext: true,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'small-intestine',
        },
        {
          title: 'Large Intestine',
          video: process.env.NODE_ENV === 'production' ? '497760671' : '169900085',
          autoPlayNext: true,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'large-intestine',
        },
        {
          title: 'Pericardium',
          video: process.env.NODE_ENV === 'production' ? '497761393' : '358039097',
          autoPlayNext: true,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'pericardium',
        },
        {
          title: 'Heart',
          video: process.env.NODE_ENV === 'production' ? '497762066' : '355495764',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'heart',
        },
      ],
    },
    {
      type: 'half-uber',
      breakIndex: 2,
      color: '#af8b0f',
      lines: [],
      sound: ['constellation_3.aac', 'constellation_3.ogg'],
      zodiacs: [
        {
          title: 'Spleen',
          video: process.env.NODE_ENV === 'production' ? '507786888' : '358039097',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'spleen',
        },
        {
          title: 'Gall Bladder',
          video: process.env.NODE_ENV === 'production' ? '497776431' : '389051037',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'galbladder',
        },
        {
          title: 'Lung',
          video: process.env.NODE_ENV === 'production' ? '497777088' : '169900085',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'lung',
        },
        {
          title: 'Stomach',
          video: process.env.NODE_ENV === 'production' ? '497777623' : '358039097',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'stomach',
        },
        {
          title: 'Rat',
          video: process.env.NODE_ENV === 'production' ? '497778140' : '355495764',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'rat',
        },
        {
          title: 'Tiger',
          video: process.env.NODE_ENV === 'production' ? '497779117' : '355495764',
          autoPlayNext: false,
          visited: false,
          visible: false,
          hovered: false,
          starID: 'tiger',
        },
      ],
    },
  ],
  textureData: [
    { id: 'pig', image: 'images/stars/pig.png', text: 'Pig' },
    { id: 'dog', image: 'images/stars/dog.png', text: 'Dog' },
    { id: 'rooster', image: 'images/stars/rooster.png', text: 'Rooster' },
    { id: 'monkey', image: 'images/stars/monkey.png', text: 'Monkey' },
    { id: 'sheep', image: 'images/stars/sheep.png', text: 'Sheep' },
    { id: 'horse', image: 'images/stars/horse.png', text: 'Horse' },
    { id: 'snake', image: 'images/stars/snake.png', text: 'Snake' },
    { id: 'dragon', image: 'images/stars/dragon.png', text: 'Dragon' },
    { id: 'rabbit', image: 'images/stars/rabbit.png', text: 'Rabbit' },
    { id: 'tiger', image: 'images/stars/tiger.png', text: 'Tiger' },
    { id: 'ox', image: 'images/stars/ox.png', text: 'Ox' },
    { id: 'rat', image: 'images/stars/rat.png', text: 'Rat' },

    {
      id: 'tripple-burner',
      image: 'images/stars/tripple-burner.png',
      text: 'TRIPPLE BURNER',
    },
    { id: 'pericardium', image: 'images/stars/pericardium.png', text: 'Pericardium' },
    { id: 'kidney', image: 'images/stars/kidney.png', text: 'Kidney' },
    { id: 'bladder', image: 'images/stars/bladder.png', text: 'Bladder' },
    {
      id: 'small-intestine',
      image: 'images/small-intestine.png',
      text: 'Small INTESTINE',
    },
    { id: 'heart', image: 'images/stars/heart.png', text: 'Heart' },
    { id: 'spleen', image: 'images/stars/spleen.png', text: 'Spleen' },
    { id: 'stomach', image: 'images/stars/stomach.png', text: 'Stomach' },
    {
      id: 'large-intestine',
      image: 'images/stars/large-intestine.png',
      text: 'LARGE INTESTINE',
    },
    { id: 'lung', image: 'images/stars/lung.png', text: 'Lung' },
    { id: 'liver', image: 'images/stars/liver.png', text: 'Liver' },
    { id: 'galbladder', image: 'images/stars/galbladder.png', text: 'Gall Bladder' },
  ],
  yingYang: {
    core: { yin: '504605904', yang: '504606825' },
    elements: [
      {
        key: 'fire',
        details: {
          title: 'Fire',
          direction: 'South',
          season: 'Summer',
          climate: 'Hot',
          color: 'Red',
          shape: 'Angular',
          planet: 'Mars',
          heavenlyCreature: 'Vermilion Bird',
          heavenlyStems: '3rd (Bing), 4th (Ding)',
          taste: 'Bitter',
          sensoryOrgans: 'Tongue',
          spiritualDimensions: 'Spirit',
          beneficialEmotions: 'Respect, self sacrifice',
          damagingEmotions: ' Excessive Joy',
          note: 'Zhi (sol)',
          tone: 'Chih (zhi)',
          description:
            'Fire energy is connective. It is the time of activity, warmth, movement, growth and relationship. <br />Fire energy in Nature is about growing, moving, reproducing and gathering in groups. Humans<br>' +
            'and plants flourish with the warmth of the sun, there is an uplifted, joyful feeling and a sense' +
            'of safety and ease.',
          fixedElement: 'Chinese Zodiac Sign',
          signs: [
            { key: 'snake', variation: 'yang' },
            { key: 'horse', variation: 'yin' },
          ],
        },
        yin: '502862014',
        yang: '505015674',
      },
      {
        key: 'earth',
        details: {
          title: 'Earth',
          direction: 'Centre',
          season: 'Intermediate',
          climate: 'Damp',
          color: 'Yellow',
          shape: 'Square',
          planet: 'Saturn',
          heavenlyCreature: 'Yellow Dragon',
          heavenlyStems: '5th (Wu), 6th (Ji)',
          taste: 'Sweet',
          sensoryOrgans: 'Mouth',
          spiritualDimensions: 'Intention ',
          beneficialEmotions: 'Honesty, trustworthiness',
          damagingEmotions: 'Worry',
          note: 'Gong (Do)',
          tone: 'Keng (Gong)',
          description:
            'Earth is about our feelings of stability. It is the pause between the rising elements of Wood and Fire and' +
            'the descending elements of metal and water. Earth represents nourishing milk, having an abundance of ' +
            'compassion and the ability to care for young. Earth energy helps us feel protected and gives us space ' +
            'to grow. Earth energy is also the time of harvest, a time of plenty, of reaping what we have worked on ' +
            'throughout the year.',
          fixedElement: 'Chinese Zodiac Sign',
          signs: [
            { key: 'ox', variation: 'yin' },
            { key: 'dragon', variation: 'yang' },
            { key: 'sheep', variation: 'yin' },
            { key: 'dog', variation: 'yang' },
          ],
        },
        yin: '508656260',
        yang: '505015158',
      },
      {
        key: 'metal',
        details: {
          title: 'Metal',
          direction: 'West',
          season: 'Autumn',
          climate: 'Dry',
          color: 'White',
          shape: 'Round',
          planet: 'Venus',
          heavenlyCreature: 'White Tiger',
          heavenlyStems: '7th (Geng), 8th (Xin)',
          taste: 'Spicey',
          sensoryOrgans: 'Nose',
          spiritualDimensions: 'Corporeal Soul (po) ',
          beneficialEmotions: 'Discernment, proprietary',
          damagingEmotions: 'Grief (ku)',
          note: 'Shang (Re)',
          tone: 'Shang',
          description:
            'Metal is about our own purity, our spiritual essence and our ability to have connection with ‘the divine’.<br>' +
            'Metal gives us connection to the heavenly realms and helps us understand we are connected to ‘all that is’ ' +
            'providing us the source to our true self esteem. Metal is the source of such emotions as loyalty, conceit, ' +
            'good self-esteem and longing for the past.',
          fixedElement: 'Chinese Zodiac Sign',
          signs: [
            { key: 'monkey', variation: 'yang' },
            { key: 'rooster', variation: 'yin' },
          ],
        },
        yin: '509279469',
        yang: '509039112',
      },
      {
        key: 'water',
        details: {
          title: 'Water',
          direction: 'North',
          season: 'Winter',
          climate: 'Cold',
          color: 'Back, Blue',
          shape: 'Undulating',
          planet: 'Mercury',
          heavenlyCreature: 'Black Tortoise',
          heavenlyStems: '9th (Ren), 10th (Gui)',
          taste: 'Salty',
          sensoryOrgans: 'Ear',
          spiritualDimensions: 'Vital essence',
          beneficialEmotions: 'Wisdom ',
          damagingEmotions: 'Fear',
          note: 'Yu (La)',
          tone: 'Yue ',
          description:
            'Water is the realm of fear, drive, ambition, flexibility and will power. It represents our ability to keep going ' +
            'or a feeling that we are too tired. Water is about our ability to flow and overcome obstacles. Water energy ' +
            'can be reflected in our emotions similar to the way it presents itself in Nature -as a mighty river or a ' +
            'trickling stream, the waves of the ocean, a frozen lake, a gentle rain.',
          fixedElement: 'Chinese Zodiac Sign',
          signs: [
            { key: 'pig', variation: 'yang' },
            { key: 'rat', variation: 'yin' },
          ],
        },
        yin: '507733164',
        yang: '507732085',
      },
      {
        key: 'wood',
        details: {
          title: 'Wood',
          direction: 'East',
          season: 'Spring',
          climate: 'Windy',
          color: 'Green',
          shape: 'Rectangular',
          planet: 'Jupiter',
          heavenlyCreature: 'Azure Dragon',
          heavenlyStems: '1st (Jia), 2nd (Yi)',
          taste: 'Sour',
          sensoryOrgans: 'Eyes',
          spiritualDimensions: 'Ethereal soul',
          beneficialEmotions: 'Kindness ',
          damagingEmotions: 'Anger',
          note: 'Jiao',
          tone: 'Chueh (Jiao)',
          description:
            'Wood energy is direct and assertive, moving forward making no apologies for pushing things out of its way.<br>' +
            'Wood is about hope and optimism, offering change after the quiet of winter it bursts forth from the ' +
            'ground and gives Nature its structure and shape. Wood helps us to have new ideas, move forward, ' +
            'have plans, change who we are, be assertive, have vision and hope for the future and make decisions. ',
          fixedElement: 'Chinese Zodiac Sign',
          signs: [
            { key: 'tiger', variation: 'yang' },
            { key: 'rabbit', variation: 'yin' },
          ],
        },
        yin: '502833105',
        yang: '502861367',
      },
    ],
  },
  zodiacSigns: [
    {
      key: 'rat',
      title: 'Rat',
      earthlyBranchesBirthYear: 'Zi',
      elements: 'Water',
      yinYang: 'Yang',
      lunarMonth: 'Eleventh',
      luckyNumbers: '2, 3, 6, 8; Avoid: 4, 5, 9',
      luckyFlowers: 'Lily of each and every species',
      luckyColors: 'gold, blue, green; Avoid: yellow, brown',
      season: 'Winter',
      compatibility: {
        bestMatch: 'Dragon, Monkey',
        favorableMatch: 'Ox',
        averageMatch: 'Pig, Rat, Tiger, Rabbit, Rooster, Dog, Snake',
        badMatch: 'Horse',
        harmfulMatch: 'Sheep',
      },
    },
    {
      key: 'ox',
      title: 'Ox',
      earthlyBranchesBirthYear: 'Chǒu',
      elements: 'Earth',
      yinYang: 'Yin',
      lunarMonth: 'Twelfth',
      luckyNumbers: '8, 9, 3; Avoid: 6',
      luckyFlowers: ' tulip, evergreen, peach blossom, rose',
      luckyColors: 'blue, red, purple; Avoid: white, green',
      season: 'Winter',
      compatibility: {
        bestMatch: 'Snake, Rooster',
        favorableMatch: 'Rat',
        averageMatch: 'Pig, Ox, Monkey, Dog, Tiger, Rabbit, Dragon',
        badMatch: 'Sheep',
        harmfulMatch: 'Horse',
      },
    },
    {
      key: 'tiger',
      title: 'Tiger',
      earthlyBranchesBirthYear: 'Yin',
      elements: 'Wood',
      yinYang: 'Yang',
      lunarMonth: 'First',
      luckyNumbers: '0, 1, 3, 4; Avoid: 6, 7, 8',
      luckyFlowers: 'Cineraria',
      luckyColors: 'grey, white, blue, purple, orange; Avoid: gold, silver, brown, black',
      season: 'Spring',
      compatibility: {
        bestMatch: 'Horse, Dog',
        favorableMatch: 'Pig',
        averageMatch: 'Tiger, Rabbit, Dragon, Rooster, Rat, Ox, Sheep',
        badMatch: 'Monkey',
        harmfulMatch: 'Snake',
      },
    },
    {
      key: 'rabbit',
      title: 'Rabbit',
      earthlyBranchesBirthYear: 'Tree',
      elements: 'Wood',
      yinYang: 'Yin',
      lunarMonth: 'Second',
      luckyNumbers: '3, 6, 9; Avoid: 1, 7, 8',
      luckyFlowers: 'Flower of fragrant plantain lily, nerve plant, snapdragon',
      luckyColors: 'Black, pink, purple, blue, red; Avoid: brown, yellow, white',
      season: 'Spring',
      compatibility: {
        bestMatch: 'Sheep, Pig',
        favorableMatch: 'Dog',
        averageMatch: 'Tiger, Rabbit, Monkey, Rat, Ox, Snake, Horse',
        badMatch: 'Rooster',
        harmfulMatch: 'Dragon',
      },
    },
    {
      key: 'dragon',
      title: 'Dragon',
      earthlyBranchesBirthYear: 'Chen',
      elements: 'Earth',
      yinYang: 'Yang',
      lunarMonth: 'Third',
      luckyNumbers: '1, 6, 7; Avoid: 3, 8, 9',
      luckyFlowers: 'Hyacinths and bleeding heart vines',
      luckyColors: 'Yellow; Avoid: blue, green',
      season: 'Spring',
      compatibility: {
        bestMatch: 'Monkey, Rat',
        favorableMatch: 'Rooster',
        averageMatch: 'Tiger, Rabbit, Dragon, Horse, Sheep, Pig, Ox',
        badMatch: 'Dog',
        harmfulMatch: 'Rabbit',
      },
    },
    {
      key: 'snake',
      title: 'Snake',
      earthlyBranchesBirthYear: 'Si',
      elements: 'Fire',
      yinYang: 'Yin',
      lunarMonth: 'Fourth',
      luckyNumbers: '2, 8, 9; Avoid: 1, 6, 7',
      luckyFlowers: 'Orchid, cactus',
      luckyColors: 'Red, light yellow, black; Avoid: white, golden, brown',
      season: 'Summer',
      compatibility: {
        bestMatch: 'Rooster, Ox',
        favorableMatch: 'Monkey',
        averageMatch: 'Snake, Horse, Sheep, Rabbit, Dragon, Dog, Rat',
        badMatch: 'Pig',
        harmfulMatch: 'Tiger',
      },
    },
    {
      key: 'horse',
      title: 'Horse',
      earthlyBranchesBirthYear: 'Wu',
      elements: 'Fire',
      yinYang: 'Yang',
      lunarMonth: 'Fifth',
      luckyNumbers: '1, 4, 5, 7, 9; Avoid: 2, 3, 6',
      luckyFlowers: 'Sunflower, jasmine',
      luckyColors: 'Gold, yellow, red, blue; Avoid: pink, brown, white',
      season: 'Summer',
      compatibility: {
        bestMatch: 'Dog, Tiger',
        favorableMatch: 'Sheep',
        averageMatch: 'Snake, Horse, Rabbit, Dragon, Monkey, Rooster, Pig',
        badMatch: 'Rat',
        harmfulMatch: 'Ox',
      },
    },
    {
      key: 'sheep',
      title: 'Sheep',
      earthlyBranchesBirthYear: 'Wei',
      elements: 'Earth',
      yinYang: 'Yin',
      lunarMonth: 'Sixth',
      luckyNumbers: '2, 3, 4, 7, 9; Avoid: 6, 8',
      luckyFlowers: 'Carnation, primrose',
      luckyColors: 'Green, red, purple; Avoid: gold, brown',
      season: 'Summer',
      compatibility: {
        bestMatch: 'Pig, Rabbit',
        favorableMatch: 'Horse',
        averageMatch: 'Snake, Sheep, Tiger, Dragon, Monkey, Rooster, Dog',
        badMatch: 'Ox',
        harmfulMatch: 'Rat',
      },
    },
    {
      key: 'monkey',
      title: 'Monkey',
      earthlyBranchesBirthYear: 'Shen',
      elements: 'Metal',
      yinYang: 'Yang',
      lunarMonth: 'Seventh',
      luckyNumbers: '1, 3, 4, 7, 8 ; Avoid: 2, 5, 9',
      luckyFlowers: 'Chrysanthemum, crape myrtle',
      luckyColors: 'White, blue; Avoid: red, black, grey',
      season: 'Autumn',
      compatibility: {
        bestMatch: 'Rat, Dragon',
        favorableMatch: 'Snake',
        averageMatch: 'Monkey, Rooster, Dog, Ox, Horse, Sheep, Rabbit',
        badMatch: 'Tiger',
        harmfulMatch: 'Pig',
      },
    },
    {
      key: 'rooster',
      title: 'Rooster',
      earthlyBranchesBirthYear: 'Yǒu',
      elements: 'Metal',
      yinYang: 'Yin',
      lunarMonth: 'Eighth',
      luckyNumbers: '5, 7, 8; Avoid: 1, 3, 9',
      luckyFlowers: 'Gladiola, impatiens, cockscomb',
      luckyColors: 'Gold, brown, yellow; Avoid: white, green',
      season: 'Autumn',
      compatibility: {
        bestMatch: 'Ox, Snake',
        favorableMatch: 'Dragon',
        averageMatch: 'Monkey, Rooster, Horse, Sheep, Pig, Rat, Tiger',
        badMatch: 'Rabbit',
        harmfulMatch: 'Dog',
      },
    },
    {
      key: 'dog',
      title: 'Dog',
      earthlyBranchesBirthYear: 'Xu',
      elements: 'Earth',
      yinYang: 'Yang',
      lunarMonth: 'Ninth',
      luckyNumbers: '3, 4, 9; Avoid: 1, 6, 7',
      luckyFlowers: 'Rose, oncidium, cymbidium, orchid',
      luckyColors: 'Green, red, purple; Avoid: blue, white, gold',
      season: 'Autumn',
      compatibility: {
        bestMatch: 'Tiger, Horse',
        favorableMatch: 'Rabbit',
        averageMatch: 'Monkey, Dog, Pig, Rat, Ox, Snake, Sheep',
        badMatch: 'Dragon',
        harmfulMatch: 'Rooster',
      },
    },
    {
      key: 'pig',
      title: 'Pig',
      earthlyBranchesBirthYear: 'Hai',
      elements: 'Water',
      yinYang: 'Yin',
      lunarMonth: 'Tenth',
      luckyNumbers: '2, 5, 6, 8; Avoid: 3, 4, 9',
      luckyFlowers: 'Lily',
      luckyColors: 'Yellow; Avoid: red, blue',
      season: 'Winter',
      compatibility: {
        bestMatch: 'Rabbit, Sheep',
        favorableMatch: 'Tiger',
        averageMatch: 'Pig, Rat, Ox, Rooster, Dog, Dragon, Horse',
        badMatch: 'Snake',
        harmfulMatch: 'Monkey',
      },
    },
  ],
  birthdaySigns: [
    {
      animal: 'Rat',
      element: 'Yang Wood',
      file: 'wood-rat.jpg',
      from: '1924-02-05',
      key: 'rat',
      sign: 'Wood Rat',
      to: '1925-01-23',
    },
    {
      animal: 'Ox',
      element: 'Yin Wood',
      file: 'wood-ox.jpg',
      from: '1925-01-24',
      key: 'ox',
      sign: 'Wood Ox',
      to: '1926-02-12',
    },
    {
      animal: 'Tiger',
      element: 'Yang Fire',
      file: 'fire-tiger.jpg',
      from: '1926-02-13',
      key: 'tiger',
      sign: 'Fire Tiger',
      to: '1927-02-01',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Fire',
      file: 'fire-rabbit.jpg',
      from: '1927-02-02',
      key: 'rabbit',
      sign: 'Fire Rabbit',
      to: '1928-01-22',
    },
    {
      animal: 'Dragon',
      element: 'Yang Earth',
      file: 'earth-dragon.jpg',
      from: '1928-01-23',
      key: 'dragon',
      sign: 'Earth Dragon',
      to: '1929-02-09',
    },
    {
      animal: 'Snake',
      element: 'Yin Earth',
      file: 'earth-snake.jpg',
      from: '1929-02-10',
      key: 'snake',
      sign: 'Earth Snake',
      to: '1930-01-29',
    },
    {
      animal: 'Horse',
      element: 'Yang Metal',
      file: 'metal-horse.jpg',
      from: '1930-01-30',
      key: 'horse',
      sign: 'Metal Horse',
      to: '1931-02-16',
    },
    {
      animal: 'Sheep',
      element: 'Yin Metal',
      file: 'metal-sheep.jpg',
      from: '1931-02-17',
      key: 'sheep',
      sign: 'Metal Sheep',
      to: '1932-02-05',
    },
    {
      animal: 'Monkey',
      element: 'Yang Water',
      file: 'water-monkey.jpg',
      from: '1932-02-06',
      key: 'monkey',
      sign: 'Water Monkey',
      to: '1933-01-25',
    },
    {
      animal: 'Rooster',
      element: 'Yin Water',
      file: 'water-rooster.jpg',
      from: '1933-01-26',
      key: 'rooster',
      sign: 'Water Rooster',
      to: '1934-02-13',
    },
    {
      animal: 'Dog',
      element: 'Yang Wood',
      file: 'wood-dog.jpg',
      from: '1934-02-14',
      key: 'dog',
      sign: 'Wood Dog',
      to: '1935-02-03',
    },
    {
      animal: 'Pig',
      element: 'Yin Wood',
      file: 'wood-pig.jpg',
      from: '1935-02-04',
      key: 'pig',
      sign: 'Wood Pig',
      to: '1936-01-23',
    },
    {
      animal: 'Rat',
      element: 'Yang Fire',
      file: 'fire-rat.jpg',
      from: '1936-01-24',
      key: 'rat',
      sign: 'Fire Rat',
      to: '1937-02-10',
    },
    {
      animal: 'Ox',
      element: 'Yin Fire',
      file: 'fire-ox.jpg',
      from: '1937-02-11',
      key: 'ox',
      sign: 'Fire Ox',
      to: '1938-01-30',
    },
    {
      animal: 'Tiger',
      element: 'Yang Earth',
      file: 'earth-tiger.jpg',
      from: '1938-01-31',
      key: 'tiger',
      sign: 'Earth Tiger',
      to: '1939-02-18',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Earth',
      file: 'earth-rabbit.jpg',
      from: '1939-02-19',
      key: 'rabbit',
      sign: 'Earth Rabbit',
      to: '1940-02-07',
    },
    {
      animal: 'Dragon',
      element: 'Yang Metal',
      file: 'metal-dragon.jpg',
      from: '1940-02-08',
      key: 'dragon',
      sign: 'Metal Dragon',
      to: '1941-01-26',
    },
    {
      animal: 'Snake',
      element: 'Yin Metal',
      file: 'metal-snake.jpg',
      from: '1941-01-27',
      key: 'snake',
      sign: 'Metal Snake',
      to: '1942-02-14',
    },
    {
      animal: 'Horse',
      element: 'Yang Water',
      file: 'water-horse.jpg',
      from: '1942-02-15',
      key: 'horse',
      sign: 'Water Horse',
      to: '1943-02-04',
    },
    {
      animal: 'Sheep',
      element: 'Yin Water',
      file: 'water-sheep.jpg',
      from: '1943-02-05',
      key: 'sheep',
      sign: 'Water Sheep',
      to: '1944-01-24',
    },
    {
      animal: 'Monkey',
      element: 'Yang Wood',
      file: 'wood-monkey.jpg',
      from: '1944-01-25',
      key: 'monkey',
      sign: 'Wood Monkey',
      to: '1945-02-12',
    },
    {
      animal: 'Rooster',
      element: 'Yin Wood',
      file: 'wood-rooster.jpg',
      from: '1945-02-13',
      key: 'rooster',
      sign: 'Wood Rooster',
      to: '1946-02-01',
    },
    {
      animal: 'Dog',
      element: 'Yang Fire',
      file: 'fire-dog.jpg',
      from: '1946-02-02',
      key: 'dog',
      sign: 'Fire Dog',
      to: '1947-01-21',
    },
    {
      animal: 'Pig',
      element: 'Yin Fire',
      file: 'fire-pig.jpg',
      from: '1947-01-22',
      key: 'pig',
      sign: 'Fire Pig',
      to: '1948-02-09',
    },
    {
      animal: 'Rat',
      element: 'Yang Earth',
      file: 'earth-rat.jpg',
      from: '1948-02-10',
      key: 'rat',
      sign: 'Earth Rat',
      to: '1949-01-28',
    },
    {
      animal: 'Ox',
      element: 'Yin Earth',
      file: 'earth-ox.jpg',
      from: '1949-01-29',
      key: 'ox',
      sign: 'Earth Ox',
      to: '1950-02-16',
    },
    {
      animal: 'Tiger',
      element: 'Yang Metal',
      file: 'metal-tiger.jpg',
      from: '1950-02-17',
      key: 'tiger',
      sign: 'Metal Tiger',
      to: '1951-02-05',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Metal',
      file: 'metal-rabbit.jpg',
      from: '1951-02-06',
      key: 'rabbit',
      sign: 'Metal Rabbit',
      to: '1952-01-26',
    },
    {
      animal: 'Dragon',
      element: 'Yang Water',
      file: 'water-dragon.jpg',
      from: '1952-01-27',
      key: 'dragon',
      sign: 'Water Dragon',
      to: '1953-02-13',
    },
    {
      animal: 'Snake',
      element: 'Yin Water',
      file: 'water-snake.jpg',
      from: '1953-02-14',
      key: 'snake',
      sign: 'Water Snake',
      to: '1954-02-02',
    },
    {
      animal: 'Horse',
      element: 'Yang Wood',
      file: 'wood-horse.jpg',
      from: '1954-02-03',
      key: 'horse',
      sign: 'Wood Horse',
      to: '1955-01-23',
    },
    {
      animal: 'Sheep',
      element: 'Yin Wood',
      file: 'wood-sheep.jpg',
      from: '1955-01-24',
      key: 'sheep',
      sign: 'Wood Sheep',
      to: '1956-02-11',
    },
    {
      animal: 'Monkey',
      element: 'Yang Fire',
      file: 'fire-monkey.jpg',
      from: '1956-02-12',
      key: 'monkey',
      sign: 'Fire Monkey',
      to: '1957-01-30',
    },
    {
      animal: 'Rooster',
      element: 'Yin Fire',
      file: 'fire-rooster.jpg',
      from: '1957-01-31',
      key: 'rooster',
      sign: 'Fire Rooster',
      to: '1958-02-17',
    },
    {
      animal: 'Dog',
      element: 'Yang Earth',
      file: 'earth-dog.jpg',
      from: '1958-02-18',
      key: 'dog',
      sign: 'Earth Dog',
      to: '1959-02-07',
    },
    {
      animal: 'Pig',
      element: 'Yin Earth',
      file: 'earth-pig.jpg',
      from: '1959-02-08',
      key: 'pig',
      sign: 'Earth Pig',
      to: '1960-01-27',
    },
    {
      animal: 'Rat',
      element: 'Yang Metal',
      file: 'metal-rat.jpg',
      from: '1960-01-28',
      key: 'rat',
      sign: 'Metal Rat',
      to: '1961-02-14',
    },
    {
      animal: 'Ox',
      element: 'Yin Metal',
      file: 'metal-ox.jpg',
      from: '1961-02-15',
      key: 'ox',
      sign: 'Metal Ox',
      to: '1962-02-04',
    },
    {
      animal: 'Tiger',
      element: 'Yang Water',
      file: 'water-tiger.jpg',
      from: '1962-02-05',
      key: 'tiger',
      sign: 'Water Tiger',
      to: '1963-01-24',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Water',
      file: 'water-rabbit.jpg',
      from: '1963-01-25',
      key: 'rabbit',
      sign: 'Water Rabbit',
      to: '1964-02-12',
    },
    {
      animal: 'Dragon',
      element: 'Yang Wood',
      file: 'wood-dragon.jpg',
      from: '1964-02-13',
      key: 'dragon',
      sign: 'Wood Dragon',
      to: '1965-02-01',
    },
    {
      animal: 'Snake',
      element: 'Yin Wood',
      file: 'wood-snake.jpg',
      from: '1965-02-02',
      key: 'snake',
      sign: 'Wood Snake',
      to: '1966-01-20',
    },
    {
      animal: 'Horse',
      element: 'Yang Fire',
      file: 'fire-horse.jpg',
      from: '1966-01-21',
      key: 'horse',
      sign: 'Fire Horse',
      to: '1967-02-08',
    },
    {
      animal: 'Sheep',
      element: 'Yin Fire',
      file: 'fire-sheep.jpg',
      from: '1967-02-09',
      key: 'sheep',
      sign: 'Fire Sheep',
      to: '1968-01-29',
    },
    {
      animal: 'Monkey',
      element: 'Yang Earth',
      file: 'earth-monkey.jpg',
      from: '1968-01-30',
      key: 'monkey',
      sign: 'Earth Monkey',
      to: '1969-02-16',
    },
    {
      animal: 'Rooster',
      element: 'Yin Earth',
      file: 'earth-rooster.jpg',
      from: '1969-02-17',
      key: 'rooster',
      sign: 'Earth Rooster',
      to: '1970-02-05',
    },
    {
      animal: 'Dog',
      element: 'Yang Metal',
      file: 'metal-dog.jpg',
      from: '1970-02-06',
      key: 'dog',
      sign: 'Metal Dog',
      to: '1971-01-26',
    },
    {
      animal: 'Pig',
      element: 'Yin Metal',
      file: 'metal-pig.jpg',
      from: '1971-01-27',
      key: 'pig',
      sign: 'Metal Pig',
      to: '1972-02-14',
    },
    {
      animal: 'Rat',
      element: 'Yang Water',
      file: 'water-rat.jpg',
      from: '1972-02-15',
      key: 'rat',
      sign: 'Water Rat',
      to: '1973-02-02',
    },
    {
      animal: 'Ox',
      element: 'Yin Water',
      file: 'water-ox.jpg',
      from: '1973-02-03',
      key: 'ox',
      sign: 'Water Ox',
      to: '1974-01-22',
    },
    {
      animal: 'Tiger',
      element: 'Yang Wood',
      file: 'wood-tiger.jpg',
      from: '1974-01-23',
      key: 'tiger',
      sign: 'Wood Tiger',
      to: '1975-02-10',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Wood',
      file: 'wood-rabbit.jpg',
      from: '1975-02-11',
      key: 'rabbit',
      sign: 'Wood Rabbit',
      to: '1976-01-30',
    },
    {
      animal: 'Dragon',
      element: 'Yang Fire',
      file: 'fire-dragon.jpg',
      from: '1976-01-31',
      key: 'dragon',
      sign: 'Fire Dragon',
      to: '1977-02-17',
    },
    {
      animal: 'Snake',
      element: 'Yin Fire',
      file: 'fire-snake.jpg',
      from: '1977-02-18',
      key: 'snake',
      sign: 'Fire Snake',
      to: '1978-02-06',
    },
    {
      animal: 'Horse',
      element: 'Yang Earth',
      file: 'earth-horse.jpg',
      from: '1978-02-07',
      key: 'horse',
      sign: 'Earth Horse',
      to: '1979-01-27',
    },
    {
      animal: 'Sheep',
      element: 'Yin Earth',
      file: 'earth-sheep.jpg',
      from: '1979-01-28',
      key: 'sheep',
      sign: 'Earth Sheep',
      to: '1980-02-15',
    },
    {
      animal: 'Monkey',
      element: 'Yang Metal',
      file: 'metal-monkey.jpg',
      from: '1980-02-16',
      key: 'monkey',
      sign: 'Metal Monkey',
      to: '1981-02-04',
    },
    {
      animal: 'Rooster',
      element: 'Yin Metal',
      file: 'metal-rooster.jpg',
      from: '1981-02-05',
      key: 'rooster',
      sign: 'Metal Rooster',
      to: '1982-01-24',
    },
    {
      animal: 'Dog',
      element: 'Yang Water',
      file: 'water-dog.jpg',
      from: '1982-01-25',
      key: 'dog',
      sign: 'Water Dog',
      to: '1983-02-12',
    },
    {
      animal: 'Pig',
      element: 'Yin Water',
      file: 'water-pig.jpg',
      from: '1983-02-13',
      key: 'pig',
      sign: 'Water Pig',
      to: '1984-02-01',
    },
    {
      animal: 'Rat',
      element: 'Yang Wood',
      file: 'wood-rat.jpg',
      from: '1984-02-02',
      key: 'rat',
      sign: 'Wood Rat',
      to: '1985-02-19',
    },
    {
      animal: 'Ox',
      element: 'Yin Wood',
      file: 'wood-ox.jpg',
      from: '1985-02-20',
      key: 'ox',
      sign: 'Wood Ox',
      to: '1986-02-08',
    },
    {
      animal: 'Tiger',
      element: 'Yang Fire',
      file: 'fire-tiger.jpg',
      from: '1986-02-09',
      key: 'tiger',
      sign: 'Fire Tiger',
      to: '1987-01-28',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Fire',
      file: 'fire-rabbit.jpg',
      from: '1987-01-29',
      key: 'rabbit',
      sign: 'Fire Rabbit',
      to: '1988-02-16',
    },
    {
      animal: 'Dragon',
      element: 'Yang Earth',
      file: 'earth-dragon.jpg',
      from: '1988-02-17',
      key: 'dragon',
      sign: 'Earth Dragon',
      to: '1989-02-05',
    },
    {
      animal: 'Snake',
      element: 'Yin Earth',
      file: 'earth-snake.jpg',
      from: '1989-02-06',
      key: 'snake',
      sign: 'Earth Snake',
      to: '1990-01-26',
    },
    {
      animal: 'Horse',
      element: 'Yang Metal',
      file: 'metal-horse.jpg',
      from: '1990-01-27',
      key: 'horse',
      sign: 'Metal Horse',
      to: '1991-02-14',
    },
    {
      animal: 'Sheep',
      element: 'Yin Metal',
      file: 'metal-sheep.jpg',
      from: '1991-02-15',
      key: 'sheep',
      sign: 'Metal Sheep',
      to: '1992-02-03',
    },
    {
      animal: 'Monkey',
      element: 'Yang Water',
      file: 'water-monkey.jpg',
      from: '1992-02-04',
      key: 'monkey',
      sign: 'Water Monkey',
      to: '1993-01-22',
    },
    {
      animal: 'Rooster',
      element: 'Yin Water',
      file: 'water-rooster.jpg',
      from: '1993-01-23',
      key: 'rooster',
      sign: 'Water Rooster',
      to: '1994-02-09',
    },
    {
      animal: 'Dog',
      element: 'Yang Wood',
      file: 'wood-dog.jpg',
      from: '1994-02-10',
      key: 'dog',
      sign: 'Wood Dog',
      to: '1995-01-30',
    },
    {
      animal: 'Pig',
      element: 'Yin Wood',
      file: 'wood-pig.jpg',
      from: '1995-01-31',
      key: 'pig',
      sign: 'Wood Pig',
      to: '1996-02-18',
    },
    {
      animal: 'Rat',
      element: 'Yang Fire',
      file: 'fire-rat.jpg',
      from: '1996-02-19',
      key: 'rat',
      sign: 'Fire Rat',
      to: '1997-02-06',
    },
    {
      animal: 'Ox',
      element: 'Yin Fire',
      file: 'fire-ox.jpg',
      from: '1997-02-07',
      key: 'ox',
      sign: 'Fire Ox',
      to: '1998-01-27',
    },
    {
      animal: 'Tiger',
      element: 'Yang Earth',
      file: 'earth-tiger.jpg',
      from: '1998-01-28',
      key: 'tiger',
      sign: 'Earth Tiger',
      to: '1999-02-15',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Earth',
      file: 'earth-rabbit.jpg',
      from: '1999-02-16',
      key: 'rabbit',
      sign: 'Earth Rabbit',
      to: '2000-02-04',
    },
    {
      animal: 'Dragon',
      element: 'Yang Metal',
      file: 'metal-dragon.jpg',
      from: '2000-02-05',
      key: 'dragon',
      sign: 'Metal Dragon',
      to: '2001-01-23',
    },
    {
      animal: 'Snake',
      element: 'Yin Metal',
      file: 'metal-snake.jpg',
      from: '2001-01-24',
      key: 'snake',
      sign: 'Metal Snake',
      to: '2002-02-11',
    },
    {
      animal: 'Horse',
      element: 'Yang Water',
      file: 'water-horse.jpg',
      from: '2002-02-12',
      key: 'horse',
      sign: 'Water Horse',
      to: '2003-01-31',
    },
    {
      animal: 'Sheep',
      element: 'Yin Water',
      file: 'water-sheep.jpg',
      from: '2003-02-01',
      key: 'sheep',
      sign: 'Water Sheep',
      to: '2004-01-21',
    },
    {
      animal: 'Monkey',
      element: 'Yang Wood',
      file: 'wood-monkey.jpg',
      from: '2004-01-22',
      key: 'monkey',
      sign: 'Wood Monkey',
      to: '2005-02-08',
    },
    {
      animal: 'Rooster',
      element: 'Yin Wood',
      file: 'wood-rooster.jpg',
      from: '2005-02-09',
      key: 'rooster',
      sign: 'Wood Rooster',
      to: '2006-01-28',
    },
    {
      animal: 'Dog',
      element: 'Yang Fire',
      file: 'fire-dog.jpg',
      from: '2006-01-29',
      key: 'dog',
      sign: 'Fire Dog',
      to: '2007-02-17',
    },
    {
      animal: 'Pig',
      element: 'Yin Fire',
      file: 'fire-pig.jpg',
      from: '2007-02-18',
      key: 'pig',
      sign: 'Fire Pig',
      to: '2008-02-06',
    },
    {
      animal: 'Rat',
      element: 'Yang Earth',
      file: 'earth-rat.jpg',
      from: '2008-02-07',
      key: 'rat',
      sign: 'Earth Rat',
      to: '2009-01-25',
    },
    {
      animal: 'Ox',
      element: 'Yin Earth',
      file: 'earth-ox.jpg',
      from: '2009-01-26',
      key: 'ox',
      sign: 'Earth Ox',
      to: '2010-02-13',
    },
    {
      animal: 'Tiger',
      element: 'Yang Metal',
      file: 'metal-tiger.jpg',
      from: '2010-02-14',
      key: 'tiger',
      sign: 'Metal Tiger',
      to: '2011-02-02',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Metal',
      file: 'metal-rabbit.jpg',
      from: '2011-02-03',
      key: 'rabbit',
      sign: 'Metal Rabbit',
      to: '2012-01-22',
    },
    {
      animal: 'Dragon',
      element: 'Yang Water',
      file: 'water-dragon.jpg',
      from: '2012-01-23',
      key: 'dragon',
      sign: 'Water Dragon',
      to: '2013-02-09',
    },
    {
      animal: 'Snake',
      element: 'Yin Water',
      file: 'water-snake.jpg',
      from: '2013-02-10',
      key: 'snake',
      sign: 'Water Snake',
      to: '2014-01-30',
    },
    {
      animal: 'Horse',
      element: 'Yang Wood',
      file: 'wood-horse.jpg',
      from: '2014-01-31',
      key: 'horse',
      sign: 'Wood Horse',
      to: '2015-02-18',
    },
    {
      animal: 'Sheep',
      element: 'Yin Wood',
      file: 'wood-sheep.jpg',
      from: '2015-02-19',
      key: 'sheep',
      sign: 'Wood Sheep',
      to: '2016-02-07',
    },
    {
      animal: 'Monkey',
      element: 'Yang Fire',
      file: 'fire-monkey.jpg',
      from: '2016-02-08',
      key: 'monkey',
      sign: 'Fire Monkey',
      to: '2017-01-27',
    },
    {
      animal: 'Rooster',
      element: 'Yin Fire',
      file: 'fire-rooster.jpg',
      from: '2017-01-28',
      key: 'rooster',
      sign: 'Fire Rooster',
      to: '2018-02-15',
    },
    {
      animal: 'Dog',
      element: 'Yang Earth',
      file: 'earth-dog.jpg',
      from: '2018-02-16',
      key: 'dog',
      sign: 'Earth Dog',
      to: '2019-02-04',
    },
    {
      animal: 'Pig',
      element: 'Yin Earth',
      file: 'earth-pig.jpg',
      from: '2019-02-05',
      key: 'pig',
      sign: 'Earth Pig',
      to: '2020-01-24',
    },
    {
      animal: 'Rat',
      element: 'Yang Metal',
      file: 'metal-rat.jpg',
      from: '2020-01-25',
      key: 'rat',
      sign: 'Metal Rat',
      to: '2021-02-11',
    },
    {
      animal: 'Ox',
      element: 'Yin Metal',
      file: 'metal-ox.jpg',
      from: '2021-02-12',
      key: 'ox',
      sign: 'Metal Ox',
      to: '2022-01-31',
    },
    {
      animal: 'Tiger',
      element: 'Yang Water',
      file: 'water-tiger.jpg',
      from: '2022-02-01',
      key: 'tiger',
      sign: 'Water Tiger',
      to: '2023-01-21',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Water',
      file: 'water-rabbit.jpg',
      from: '2023-01-22',
      key: 'rabbit',
      sign: 'Water Rabbit',
      to: '2024-02-09',
    },
    {
      animal: 'Dragon',
      element: 'Yang Wood',
      file: 'wood-dragon.jpg',
      from: '2024-02-10',
      key: 'dragon',
      sign: 'Wood Dragon',
      to: '2025-01-28',
    },
    {
      animal: 'Snake',
      element: 'Yin Wood',
      file: 'wood-snake.jpg',
      from: '2025-01-29',
      key: 'snake',
      sign: 'Wood Snake',
      to: '2026-02-16',
    },
    {
      animal: 'Horse',
      element: 'Yang Fire',
      file: 'fire-horse.jpg',
      from: '2026-02-17',
      key: 'horse',
      sign: 'Fire Horse',
      to: '2027-02-05',
    },
    {
      animal: 'Sheep',
      element: 'Yin Fire',
      file: 'fire-sheep.jpg',
      from: '2027-02-06',
      key: 'sheep',
      sign: 'Fire Sheep',
      to: '2028-01-25',
    },
    {
      animal: 'Monkey',
      element: 'Yang Earth',
      file: 'earth-monkey.jpg',
      from: '2028-01-26',
      key: 'monkey',
      sign: 'Earth Monkey',
      to: '2029-02-12',
    },
    {
      animal: 'Rooster',
      element: 'Yin Earth',
      file: 'earth-rooster.jpg',
      from: '2029-02-13',
      key: 'rooster',
      sign: 'Earth Rooster',
      to: '2030-02-02',
    },
    {
      animal: 'Dog',
      element: 'Yang Metal',
      file: 'metal-dog.jpg',
      from: '2030-02-03',
      key: 'dog',
      sign: 'Metal Dog',
      to: '2031-01-22',
    },
    {
      animal: 'Pig',
      element: 'Yin Metal',
      file: 'metal-pig.jpg',
      from: '2031-01-23',
      key: 'pig',
      sign: 'Metal Pig',
      to: '2032-02-10',
    },
    {
      animal: 'Rat',
      element: 'Yang Water',
      file: 'water-rat.jpg',
      from: '2032-02-11',
      key: 'rat',
      sign: 'Water Rat',
      to: '2033-01-30',
    },
    {
      animal: 'Ox',
      element: 'Yin Water',
      file: 'water-ox.jpg',
      from: '2033-01-31',
      key: 'ox',
      sign: 'Water Ox',
      to: '2034-02-18',
    },
    {
      animal: 'Tiger',
      element: 'Yang Wood',
      file: 'wood-tiger.jpg',
      from: '2034-02-19',
      key: 'tiger',
      sign: 'Wood Tiger',
      to: '2035-02-07',
    },
    {
      animal: 'Rabbit',
      element: 'Yin Wood',
      file: 'wood-rabbit.jpg',
      from: '2035-02-08',
      key: 'rabbit',
      sign: 'Wood Rabbit',
      to: '2036-01-27',
    },
    {
      animal: 'Dragon',
      element: 'Yang Fire',
      file: 'fire-dragon.jpg',
      from: '2036-01-28',
      key: 'dragon',
      sign: 'Fire Dragon',
      to: '2037-02-14',
    },
    {
      animal: 'Snake',
      element: 'Yin Fire',
      file: 'fire-snake.jpg',
      from: '2037-02-15',
      key: 'snake',
      sign: 'Fire Snake',
      to: '2038-02-03',
    },
    {
      animal: 'Horse',
      element: 'Yang Earth',
      file: 'earth-horse.jpg',
      from: '2038-02-04',
      key: 'horse',
      sign: 'Earth Horse',
      to: '2039-01-23',
    },
    {
      animal: 'Sheep',
      element: 'Yin Earth',
      file: 'earth-sheep.jpg',
      from: '2039-01-24',
      key: 'sheep',
      sign: 'Earth Sheep',
      to: '2040-02-11',
    },
    {
      animal: 'Monkey',
      element: 'Yang Metal',
      file: 'metal-monkey.jpg',
      from: '2040-02-12',
      key: 'monkey',
      sign: 'Metal Monkey',
      to: '2041-01-31',
    },
    {
      animal: 'Rooster',
      element: 'Yin Metal',
      file: 'metal-rooster.jpg',
      from: '2041-02-01',
      key: 'rooster',
      sign: 'Metal Rooster',
      to: '2042-01-21',
    },
    {
      animal: 'Dog',
      element: 'Yang Water',
      file: 'water-dog.jpg',
      from: '2042-01-22',
      key: 'dog',
      sign: 'Water Dog',
      to: '2043-02-09',
    },
    {
      animal: 'Pig',
      element: 'Yin Water',
      file: 'water-pig.jpg',
      from: '2043-02-10',
      key: 'pig',
      sign: 'Water Pig',
      to: '2044-01-29',
    },
  ],
}
export const MainContext = React.createContext(null)
export const CanvasContext = React.createContext(null)

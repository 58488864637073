import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import './_ZodiacDetails.scss'
import { MainContext } from '../state/MainContext'
import { Link, Redirect } from 'react-router-dom'
import { startCase } from 'lodash'

const ZodiacDetails = ({ element, variation }) => {
  const [state] = useContext(MainContext)
  const myBirthday = state.birthday

  let zodiac = null
  for (let i = 0; i < state.birthdaySigns.length; i++) {
    if (moment(myBirthday).isBetween(state.birthdaySigns[i].from, state.birthdaySigns[i].to)) {
      zodiac = state.zodiacSigns.filter((sign) => sign.key === state.birthdaySigns[i].key)[0]
      console.log(zodiac, state.birthdaySigns[i])
      zodiac.image = state.birthdaySigns[i].file
      zodiac.fullTitle = state.birthdaySigns[i].sign
      break
    }
  }
  const [redirection, setRedirection] = useState('')

  useEffect(() => {
    if (myBirthday === '') setRedirection('/')
  }, [])

  return (
    <>
      {redirection !== '' && <Redirect push to={redirection} />}
      {zodiac && (
        <div className="zodiac-details--wrapper">
          <div className="zodiac-details--icon">
            <img src={`/assets/images/ying-yang/zodiacs/${zodiac.image}`} />
          </div>
          <div className="zodiac-details--listing">
            <div>
              <div className="zodiac-details--title-wrapper">
                <div className="zodiac-details--title">{zodiac.fullTitle}</div>
              </div>
              <div className="zodiac-details--specs">
                <div className="zodiac-details--spec-column">
                  <div className="zodiac-details--spec-line-left">
                    <div>Earthly Branches of Birth Year:</div>{' '}
                    <div>{zodiac.earthlyBranchesBirthYear}</div>
                  </div>
                  <div className="zodiac-details--spec-line-left">
                    <div>The Five Elements:</div> <div>{zodiac.elements}</div>
                  </div>
                  <div className="zodiac-details--spec-line-left">
                    <div>Yin Yang:</div> <div> {zodiac.yinYang}</div>
                  </div>
                  <div className="zodiac-details--spec-line-left">
                    <div>Lunar Month: </div> <div>{zodiac.lunarMonth}</div>
                  </div>
                </div>
                <div className="zodiac-details--spec-column">
                  <div className="zodiac-details--spec-line-right">
                    <div>Lucky Numbers:</div> <div> {zodiac.luckyNumbers}</div>
                  </div>
                  <div className="zodiac-details--spec-line-right">
                    <div>Lucky Flowers:</div>
                    <div> {zodiac.luckyFlowers}</div>
                  </div>
                  <div className="zodiac-details--spec-line-right">
                    <div>Lucky Colors:</div> <div> {zodiac.luckyColors}</div>
                  </div>
                  <div className="zodiac-details--spec-line-right">
                    <div>Season:</div> <div> {zodiac.season}</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="zodiac-details--signs-title">Compatibility</div>
              <div className="zodiac-details--footer">
                <div className="zodiac-details--footer-wrapper">
                  <div className="zodiac-details--footer-title">
                    Best Match
                    <br />
                    (Compatible)
                  </div>
                  <div>{zodiac.compatibility.bestMatch}</div>
                </div>
                <div className="zodiac-details--footer-wrapper">
                  <div className="zodiac-details--footer-title">
                    Favorable Match
                    <br />
                    (Compatible)
                  </div>
                  <div>{zodiac.compatibility.favorableMatch}</div>
                </div>
                <div className="zodiac-details--footer-wrapper">
                  <div className="zodiac-details--footer-title">
                    Average Match
                    <br />
                    (Link)
                  </div>
                  <div>{zodiac.compatibility.averageMatch}</div>
                </div>
                <div className="zodiac-details--footer-wrapper">
                  <div className="zodiac-details--footer-title">
                    Bad Match
                    <br />
                    (Conflict)
                  </div>
                  <div>{zodiac.compatibility.badMatch}</div>
                </div>
                <div className="zodiac-details--footer-wrapper">
                  <div className="zodiac-details--footer-title">
                    Harmful Match
                    <br />
                    (Avoid)
                  </div>
                  <div>{zodiac.compatibility.harmfulMatch}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ZodiacDetails

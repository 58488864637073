import React from 'react'
import './_About.scss'

const About = () => {
  return (
    <div className="about--container">
      <div className="about--content">
        <h1>Firehorse and Shadow</h1>
        <h2>a web performance</h2>
        <section>
          <div>
            <span>
              Choreographer / Writer / Performer - <strong>Andrea Nann</strong>
            </span>
            <br />
            <span>
              Shadow Artist / Performer - <strong>Annie Katsura Rollins</strong>
            </span>
            <br />
            <span>
              Director of Video Content/Editor - <strong>Henry Mak</strong>{' '}
            </span>
            <br />
            <span>
              Dramaturg / Readings - <strong>Cindy Mochizuki</strong>{' '}
            </span>
            <br />
            <span>
              Creative and directorial contributor - <strong>Sarah Chase</strong>{' '}
            </span>
            <br />
            <span>
              Web Designer / User Experience Designer/Website Animations -{' '}
              <strong>Elysha Poirier</strong>
            </span>
            <br />
            <span>
              Technical Director /Interactive Developer - Web experience -{' '}
              <strong>Omar Faleh</strong>
            </span>
            <br />
            <span>
              Sound Design & Composition - <strong>Ben Grossman</strong>
            </span>
            <br />
            <span>
              Director of Photography - <strong>Eric Chan</strong>
            </span>
            <br />
            <span>
              Researcher Chinese Five Elements Theory : <strong>Wu Xing - Brendan Wyatt</strong>
            </span>
            <br />
            <span>
              Theatrical Lighting Designer - <strong>Jennifer Lennon</strong>
            </span>
            <br />
            <span>
              Production Team - <strong>Laura Cournoyea & A.J. Morra</strong>
            </span>
            <br />
            <span>
              Closed Captions - <strong>Gillian Grant</strong>
            </span>
          </div>
        </section>
        <section>
          The creation of this work was generously supported by the Dance Artist in Residence
          program at Banff Centre for Arts and Creativity and by National Ballet of Canada's
          CreativAction Open Space program. Firehorse & Shadow was originally commissioned by
          CanAsian Dance for its 2020 KickStart program.
        </section>
        <section>
          Thank you: Cheryl Lalonde, Maurisa and Sue, Ramona Mar, Doug Nicolle, Saintfield Wong,
          Adam Germaine, Collective Space, Domini Anne, Anne Bourne, Peter Mettler, Omer Yukseker,
          Deanna Wong, Denise Fujiwara, The Theatre Centre, Adina Herling, Hayley Goldenberg,
          Michelle Silagy, Rick Hyslop, CanAsian Dance, Acces Asie, Tangente, and Sahara Morimoto
          for sharing and inspiring joy and playfulness throughout this process.
        </section>
        <section>
          *Beverly Nann’s interview footage is extracted from ‘Saltwater City’ (1986)
          Director/Producer: Douglas Nicolle, Associate Producer: Carol Ann Lee, Writer: Paddy
          McCallum, Host: Ramona Ma. A Spectra Communications production in association with CBC
        </section>
        <section>
          Firehorse and Shadow produced by Dreamwalker Dance Company. To learn more about our
          programming visit{' '}
          <a href="https://www.dreamwalkerdance.com" rel="noreferrer" target="_blank">
            www.dreamwalkerdance.com
          </a>
        </section>
        <h2>CREATIVE TEAM</h2>
        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/andrea.png" alt="Andrea Nann" />
            </div>
            <div className="about--bio-text">
              Andrea Nann
              <br />
              Andrea is a contemporary dance artist, producer, curator, facilitator, instigator,
              community mobilizer, consultant/mentor, and founding artistic director of Dreamwalker.
              She creates work for the stage, film and outdoor sites; investigating contemporary
              approaches to creation through collaboration with individuals from all artistic
              disciplines. Andrea dances to reach across distance, to experience others in
              celebration of possibility, diversity, connection and belonging; believing that dance
              can shift attitudes and ways of being, tuning us into what makes each of us distinct,
              to what we share, and ultimately how we can live together in wonderment and peace. A
              graduate of York University’s Department of Fine Arts, Andrea was a member of the
              danny Grossman Dance Company for 15 years where she created, performed and taught
              major roles from the works of Mr. Grossman and guest choreographers. In 2016 Ontario
              Contact recognized Andrea as Artist of the Year for contributions to the Performing
              Arts touring sector in Ontario, and in 2019 she received a Dora Mavor Moore Award for
              Outstanding Performance by an Individual in Dance.
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/annie.png" alt="Annie Katsura Rollins" />
            </div>
            <div className="about--bio-text">
              Annie Katsura Rollins
              <br />
              Annie is a theatre and performing object creator, designer, and performer. She
              incorporates ethnographic research and apprenticeship into her work, with particular
              interest in traditional puppet forms in Asia and their intersection with ritual
              practice and community building. She was awarded a Fulbright Fellowship to canvas
              Mainland China for the last remaining shadow puppet artists in 2011 and named
              valedictorian in 2019 for her PhD on the possibilities of preserving intangible
              performative culture at Concordia University. Annie lives in Toronto and co-curates at
              Concrete Cabaret. More info at{' '}
              <a href="http://www.anniekatsurarollins.co" target={'_blank'} rel="noreferrer">
                www.anniekatsurarollins.com
              </a>
              . *Photo: The younger pic is of my Dad and I at our farm, which is still my favorite
              place on earth. I got married there! I just wanted to be outside, in nature, in
              freedom at all times.
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/ben.png" alt="Ben Grossman" />
            </div>
            <div className="about--bio-text">
              Ben Grossman
              <br />
              Ben is a busy musician and sound artist: improviser, studio musician, composer, and
              noisemaker. He works in many fields, having played on over 100 CDs, soundtracks for
              film and television, sound design for theatre, installations, work designed for radio
              transmission, and live performances spanning early medieval music to experimental
              electronica. Ben's tools of choice are electronics, percussion, and, especially, the
              (vielle à roue)hurdy gurdy, a contemporary electro-acoustic string instrument with
              roots in the European middle ages. Through extended techniques, live-looping and
              processing, Ben seeks to use it as a physical interface into improvised sound
              creation, spontaneous composition and the exploration of acoustics, form and extended
              aesthetics.
              <br />
              In 1997 Ben studied Turkish music in Istanbul and has since done vielle workshops and
              lessons with Valentin Clastrier, Matthias Loibner, Maxou Heintzen and Simon Wascher as
              well as research in Deep Listening and improvisation with Anne Bourne. With an abiding
              interest in pushing the limits of his instruments and pushing the boundaries of
              whatever venue or medium in which he works, Ben solo releases can be found on
              Bandcamp.{' '}
              <a href="https://bengrossman.bandcamp.com" target="_blank" rel="noreferrer">
                https://bengrossman.bandcamp.com
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/cindy.png" alt="Cindy Mochizuki" />
            </div>
            <div className="about--bio-text">
              Cindy Mochizuki
              <br />
              Cindy Mochizuki creates multi-media installation, audio fiction, performance,
              animation, drawings and community-engaged projects. She has exhibited, performed and
              screened her work in Canada, US, Australia, and Japan. Recent exhibitions include the
              Vancouver Art Gallery, Burrard Arts Foundation, Richmond Art Gallery, Frye Art Museum,
              and Yonago City Museum. In 2015, she received the Vancouver’s Mayor’s Arts Award in
              New Media and Film and in 2020, received the Jack and Doris Shadbolt Foundation for
              the Visual Arts VIVA Award. Cindy is grateful to be part of this amazing team of
              artists who have come together to create Fire Horse and Shadow. The things that bring
              Cindy inspiration and joy in life are stories, fortunes, forest walks and culinary
              experiments.
              <br />
              <a href="http://www.cindymochizuki.com" target="_blank" rel="noreferrer">
                www.cindymochizuki.com
              </a>
              <br />
              *Photo: photo credit for the head shot is Adam Blasberg
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/sarah.png" alt="Sarah Chase" />
            </div>
            <div className="about--bio-text">
              Sarah Chase
              <br />
              Sarah has performed her dancestory work across Canada and Europe. She also toured with
              both Benôit Lachambre’s Dance par B. Lieux, and with German choreographer Raimund
              Hoghe. Her many works created for Canadian artists include Toronto Dance Theatre,
              Peggy Baker, Andrea Nann, Theatre Replacement, AntonijaLivingstone, Montreal Danse,
              and Marc Boivin. She is the recipient of the Jacqueline Lemieux Award for Excellence
              from the Canada Council for the Arts, and received the Prize of the Festival at the
              Munich Dance Biennale. The solos she created for both Peggy Baker, and Andrea Nann won
              Doras. She is an associate dance artist of the Canadian National Arts Centre. Sarah
              loves waking up to the chorus of frogs under the stars, the dawn chorus of birds in
              the spring, and the several generations of deer she has come to know while living on
              Hornby Island.
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/elysha.png" alt="Elysha Poirier" />
            </div>
            <div className="about--bio-text">
              Elysha Poirier
              <br />
              Elysha Poirier is a multidisciplinary artist working in animation, film and video for
              live performance. Combining digital and analog sources she creates intrinsic worlds of
              their own reflected in mixed media and 3D environments. Based in Montreal, Elysha is
              currently experimenting with 3D generative design platforms, and experimental web
              design. She's realized a wide range of installations and engaged in live performances
              for dance, experimental music, film, theatre and web. Elysha is also a
              core-collaborator with Dreamwalker Dance Company since 2009.
              <br />
              <a href="http://www.elyshapoirier.com" target="_blank" rel="noreferrer">
                www.elyshapoirier.com
              </a>
              <br />
              *Photo: From left to right - Jacob, Joscelyn and Elysha at Enniskillen Park
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/henry.png" alt="Henry Mak" />
            </div>
            <div className="about--bio-text">
              Henry Mak
              <br />
              Henry Mak is a videographer, editor, and director who has been working in the worlds
              of dance and theatre. He is interested in how gesture, presence and moment translate
              to the filmed image and in learning to offer ways of seeing and bearing respectful
              witness in the search for images that are “of” and “with”. He treasures his
              experiences working with Dreamwalker Dance Company, the Canadian Music Centre, Litmus
              Theatre, and the Toronto Fringe. He is filming and editing a dance film which will
              screen as part of CanAsian Dance’s Grit Short Dances program as well as filming and
              editing a series of videos and collaborating on livestreams for MABELLEarts.
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/omar.png" alt="Omar Faleh" />
            </div>
            <div className="about--bio-text">
              Omar Faleh
              <br />
              Omar is an interactive media designer and developer who lives between New York, USA
              and Montreal, Canada. With a background in architecture, arts, and computer science,
              Omar’s work is focused on designing playful, engaging, performative, and immersive
              media spaces where interaction is neither scripted nor assumed, and media is
              constantly reinterpreting the definitions of space and body. His work ranges from
              building interactive installations, to media scenography for theatre and dance, and
              media design for interactive exhibition spaces. <br />
              Omar' work was displayed in Montreal, New York, and The Netherlands, and his research
              work affiliation includes New York University, Concordia University, Hexagram and
              Arizona University.
              <br />
              <a href="http://www.morscad.com" target="_blank" rel="noreferrer">
                www.morscad.com
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/eric.png" alt="Eric Chan" />
            </div>
            <div className="about--bio-text">
              Eric Chan
              <br />
              Eric’s passion for the arts started early in theatre production. Having graduated from
              the Ryerson University program for Performance Production, he found his early years
              working with theatre, dance, and event companies. He later found work in television,
              where his newfound passion for creating from behind the lens grew. Over the years,
              Eric has been given the opportunity to collaborate with artists in the performance
              arts, even running into old friends along the way. He feels like in many ways, his
              journey has brought him back to his original love.
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/brendan.png" alt="Brendan Wyatt" />
            </div>
            <div className="about--bio-text">
              Brendan Wyatt
              <br />
              Brendan is a multi nominated and award-receiving artist in dance and choreography and
              has over 35 years of experience in classical, contemporary and experimental dance. He
              has performed nationally and internationally, facilitated and instructed dance
              workshops for several schools and companies, and created and realized roles in over
              200 individual works. Brendan has acted and choreographed for film including starring
              in the tiff world premiere of Father and choreographing the finale sequence in the
              film Crazy House. Brendan is inspired by art, nature, music and sound, their ability
              to fill one’s life with purpose and carve out a place of resonating in the soul. He is
              a core-collaborator with Dreamwalker Dance Company and joined the organization in
              2008.
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/jennifer.png" alt="Jennifer Lennon" />
            </div>
            <div className="about--bio-text">
              Jennifer Lennon
              <br />
              Jennifer Lennon is an award-winning lighting designer for live performance. She has
              designed for Soulpepper, Tarragon, Factory Theatre, Theatre Passe Muraille, Opera
              Atelier, Opera 5, and the Shaw Festival, among many others, in venues ranging from the
              venerable Ed Mirvish Theatre (Idomeneo, Opera Atelier) to a repurposed shipping
              container (The Container, Theatre Fix), and a 60’ lake stage (A Midsummer Night’s
              Dream, Theatre Orangeville.) Some other favourites include Yaga (Tarragon), Besbouss
              (Pleiades), and Almighty Voice and His Wife (Soulpepper). She is a graduate of York
              University, and a member of the Associated Designers of Canada. As a child, she could
              usually be found building forts or making noise, and nothing has changed. Her most
              recent “fort” is a makeshift home recording studio, where she’s having a wonderful
              raucous time making music and dreaming about lighting a live orchestra again someday.
              Jennifer is also an English editor for Kiva, an international non-profit that works to
              expand financial access to underserved communities around the world. Check them out{' '}
              <a href="http://kiva.org" target="_blank" rel="noreferrer">
                @kiva.org
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/laura.png" alt="Laura Cournoyea" />
            </div>
            <div className="about--bio-text">
              Laura Cournoyea
              <br />
              Laura Cournoyea is a Toronto-based arts manager and administrator. She takes daily
              inspiration from her incredibly talented friends and finds joy in the quiet moments at
              home with her partner, knitting and snuggling her furbabies, Zooey & Frankie. She has
              recently worked with the adelheid, Tribal Crackling Wind, Fujiwara Dance Inventions,
              Dreamwalker Dance Company, Shannon Litzenberger, Toronto Dance Theatre, Dusk Dances,
              Ontario Dances, inDance, blue ceiling dance, and CanAsian Dance Festival. Laura is the
              Arts Education Manager for tiger princess dance projects and the Company Manager and
              Community Activator for the dance:made in canada/fait au canada Festival. She was
              trained at York University’s Theatre Department, earning a Specialized B.F.A. in
              Theatre Design and Production.
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/aj.png" alt="A.J. Morra" />
            </div>
            <div className="about--bio-text">
              A.J. Morra
              <br />
              Based in Toronto, A.J. is a graduate of the Technical Theatre Program at Ryerson
              University, and has enjoyed an active career as a Technical Director, Production
              Manager, and Stage Manager for contemporary dance, circus, and theatre. Selected
              credits include work with Toronto Dance Theatre, Groundling Theatre Company,
              Dreamwalker Dance, Zata Omm Dance Projects, and Signal Theatre. “What truly inspires
              me is sharing. Sharing ideas, sharing comfort, sharing thoughts, but most importantly:
              sharing food.”
            </div>
          </div>
        </section>

        <section>
          <div className="about--bio-wrapper">
            <div className="about--bio-image">
              <img src="/assets/images/bios/gillian.png" alt="Gillian Grant" />
            </div>
            <div className="about--bio-text">
              Gillian Grant
              <br />
              Gillian Grant is a writer and has just completed her memoir, Living without knowing.
              Life with cancer and the unexpected healing from a First Nations leader. Connections
              give meaning to her life and her family is her greatest joy.
            </div>
          </div>
        </section>

        <section>
          <div className="about--sponsors-wrapper">
            <img
              src="/assets/images/supportedBy/cca.png"
              width="156"
              height="28"
              alt="Canada Council for the Arts"
            />
            <img
              src="/assets/images/supportedBy/oac.png"
              width="118"
              height="30"
              alt="Ontario Arts Council"
            />
            <img
              src="/assets/images/supportedBy/canasia.png"
              width="43"
              height="51"
              alt="Can Asian Dance"
            />
            <img
              src="/assets/images/supportedBy/tac.png"
              width="98"
              height="51"
              alt="Toronto Arts Council"
            />
            <img
              src="/assets/images/supportedBy/banff.png"
              alt="Banff Center for Arts and Creativity"
              width="121"
              height="28"
            />
            <img
              src="/assets/images/supportedBy/nb.png"
              width="98"
              height="56"
              alt="National Ballet of Canada"
            />
            <img
              src="/assets/images/supportedBy/ddc.png"
              width="60"
              height="44"
              alt="Dream Walker Dance Company"
            />
          </div>
        </section>
      </div>
    </div>
  )
}

export default About

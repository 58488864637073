import React, { useContext, useState } from 'react'

import './_Intro.scss'
import Background from '../common/Background'
import { Popover } from 'react-tiny-popover'
import Card from '../common/Card'
import { MainContext } from '../state/MainContext'
import { allowAccess } from '../state/MainActions'
import { Redirect } from 'react-router-dom'

const Intro = () => {
  const [, dispatch] = useContext(MainContext)
  const [redirection, setRedirection] = useState('')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const [error, setError] = useState('')
  const [password, setPassword] = useState('')

  const validatePassword = () => {
    console.log('validatePassword')
    // if (password === '') {
    // setError('empty')
    // } else {
    // if (password === process.env.REACT_APP_PASSWORD) {
    setError('')
    dispatch(allowAccess())
    setRedirection('/home')
    // } else {
    // setError('nomatch')
    // }
    // }
  }
  return (
    <Background>
      <div>
        {process.env.REACT_APP_LOCK_PERFOEMANCE.toString() === 'true' && (
          <Card>
            <div>
              Hang tight!
              <br />
              The performance hasn’t started yet.
              <br />
              Upcoming preview:
              <br />
              05/01/2021
            </div>
          </Card>
        )}
        {process.env.REACT_APP_LOCK_PERFOEMANCE.toString() === 'false' && (
          <>
            {redirection !== '' && <Redirect push to={redirection} />}

            {error === 'nomatch' && (
              <Card
                onClose={() => {
                  setError('')
                  setPassword('')
                }}
              >
                <div>
                  Opps!
                  <br />
                  Something went wrong...
                  <br />
                  Please try your code again.
                </div>
              </Card>
            )}
            {(error === '' || error === 'empty') && (
              <div className="intro--container">
                <h1>Firehorse & Shadow</h1>
                <h2>an interactive web performance</h2>

                {/*<div className="intro--password-wrapper">
                  <div className="intro--password-container">
                    <p>ENTER PASSCODE HERE</p>
                    <input
                      className={`intro--password ${
                        error !== '' ? 'intro--password-error' : 'intro--password-prestine'
                      }`}
                      value={password}
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          validatePassword()
                        }
                      }}
                    />
                  </div>
                  <div className="intro--password-enter" onClick={validatePassword}>
                    Enter
                  </div>
                </div> */}
                <div className="intro--password-enter" onClick={validatePassword}>
                  Enter
                </div>
              </div>
            )}
          </>
        )}
        <div className="intro--footnote">
          This experience works best with Google Chrome browsers on laptops or desktop computers.
          Get it{' '}
          <a href="https://www.google.com/intl/en_ca/chrome/" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </div>
        <div className="intro--video-footer">
          <Popover
            isOpen={isPopoverOpen}
            positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
            content={
              <div className="get-chrome--popover-content">
                <div className="get-chrome--popover-close" onClick={() => setIsPopoverOpen(false)}>
                  <img src="/assets/images/outline_close_black_24dp.png" alt="close" />
                </div>
                <h2>If the videos and audio files are not loading: </h2>
                <br />
                <ol>
                  <li>
                    Please make sure you are using an up to date version of Chrome as your browser
                  </li>
                  <li>
                    If you have the option, please view on a laptop or desktop rather than a tablet
                    or phone
                  </li>
                  <li>
                    Please check your wifi signal, if it is weak the media will take longer to load
                  </li>
                  <li>
                    If you have checked all of the above and still have issues loading the site you
                    can click here to watch a{' '}
                    <a
                      href="https://vimeo.com/511704393/02ecd6eb9b"
                      rel="noreferrer"
                      target="_blank"
                    >
                      40-minute video documentation
                    </a>{' '}
                    of the web performance.
                  </li>
                </ol>
              </div>
            }
          >
            <div>
              <a href="#" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                Having trouble?
              </a>
            </div>
          </Popover>
          <div>
            <a href="https://vimeo.com/497738081" target="_blank" rel="noreferrer">
              View Teaser
            </a>
          </div>
        </div>
      </div>
    </Background>
  )
}
export default Intro

import React, { useEffect, useState, useMemo, useRef } from 'react'
import * as THREE from 'three'
import { useFrame } from 'react-three-fiber'

const StarryBackground = () => {
  const [stars, setStars] = useState([])

  const mesh = useRef()
  const dummy = useMemo(() => new THREE.Object3D(), [])
  useEffect(() => {
    const positions = []
    for (let i = -500; i < 1500; i++) {
      positions.push({ x: Math.random() * 2000 - 500, y: Math.random() * 1000 - 700, z: i })
    }
    setStars(positions)
    // addSphere()
  }, [])

  useFrame(() => {
    if (mesh && mesh.current) {
      stars.forEach((star, i) => {
        star.z += i / 4000
        //   star.x += (mouse.current[0] - star.x) * 0.01
        //   star.y += (mouse.current[1] - star.y) * 0.01
        if (star.z > 1000) star.z -= 2000
        dummy.position.set(star.x, star.y, star.z)
        dummy.updateMatrix()
        mesh.current.setMatrixAt(i, dummy.matrix)
      })
      mesh.current.instanceMatrix.needsUpdate = true
    }
  })
  return (
    <>
      {/*
      {stars.map((star) => (
        <mesh>
          <sphereBufferGeometry position={[star.x, star.y, star.z]} args={[0.5, 32, 32]} />
          <meshStandardMaterial color={'orange'} />
        </mesh>
      ))}
      */}

      <instancedMesh ref={mesh} args={[null, null, 200]}>
        <sphereBufferGeometry attach="geometry" args={[1, 32, 32]} />
        <meshStandardMaterial color={'white'} />
      </instancedMesh>
    </>
  )
}

export default StarryBackground

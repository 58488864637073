import React, { useEffect, useState } from 'react'
import './_Placemat.scss'
import Vimeo from '@u-wave/react-vimeo'

const Placemat = () => {
  const [canPlayVideo, setCanPlayVideo] = useState(false)
  const [videoPlayerClass, setVideoPlayerClass] = useState('')

  useEffect(() => {
    window.addEventListener('click', () => {
      setCanPlayVideo(true)
    })
    return () => {
      // Clean up the subscription
      window.removeEventListener('click', () => {
        setCanPlayVideo(true)
      })
    }
  }, [])

  const onVideoStart = () => {
    setVideoPlayerClass('placemat--videoPlayer-idle')
  }
  return (
    <div className="placemat--background-container">
      <div className="placemat--background-image">
        <img src="/assets/images/PlacematBackground.jpg" alt="Placemat background" />
        {canPlayVideo && (
          <div className={`placemat--background-video ${videoPlayerClass}`}>
            <Vimeo
              style={{
                width: '100vw',
                height: '56.25vw',
                minHeight: '100vh',
                minWidth: '177.77vh',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              video="507207268"
              controls={false}
              showTitle={false}
              showByline={false}
              loop={true}
              autoplay
              width={'100%'}
              height={'100%'}
              responsive={true}
              onPlay={onVideoStart}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Placemat

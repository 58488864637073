import React, { useContext, useState } from 'react'
import './_Header.scss'
import { NavLink, Redirect } from 'react-router-dom'
import { MainContext } from '../state/MainContext'
import { experienceIsFinished, setSoundSrc } from '../state/MainActions'

const Header = ({ location }) => {
  const [state, dispatch] = useContext(MainContext)
  const [redirection, setRedirection] = useState('')

  return (
    <nav className="header">
      {redirection !== '' && <Redirect push to={redirection} />}

      <ul>
        <li>
          {location.pathname.indexOf('/constellation') < 0 && (
            <div
              className="header--link"
              activeClassName="header--link-selected"
              onClick={() => {
                dispatch(
                  setSoundSrc(
                    `/assets/audio/constellation_${state.currentConstellation}${
                      state.system.iOS ? '_ios' : ''
                    }.aac`
                  )
                )
                if (state.experienceFinished === 0) {
                  setRedirection(`/constellation/${state.currentConstellation}`)
                } else if (state.experienceFinished === 3) {
                  dispatch(experienceIsFinished(1))
                  setRedirection(`/constellation/${state.constellations.length}`)
                }
              }}
            >
              Cosmos
            </div>
          )}
          {location.pathname.indexOf('/constellation') >= 0 && (
            <span className="header--link-selected">Cosmos</span>
          )}
        </li>
        <li>
          {location.pathname.indexOf('/about') < 0 && (
            <NavLink
              exact
              to="/about"
              activeClassName="header--link-selected"
              className="header--link"
            >
              About
            </NavLink>
          )}
          {location.pathname.indexOf('/about') >= 0 && (
            <span className="header--link-selected">About</span>
          )}
        </li>
      </ul>
    </nav>
  )
}

export default Header

import React, { useContext, useState } from 'react'

import './_FinishedCover.scss'
import { MainContext } from '../state/MainContext'
import { Redirect } from 'react-router-dom'
import { resetState, setSoundSrc } from '../state/MainActions'
const FinishedCover = () => {
  const [state, dispatch] = useContext(MainContext)
  const [redirection, setRedirection] = useState('')
  const [email, setEmail] = useState('')

  return (
    <>
      {redirection !== '' && <Redirect push to={redirection} />}
      <div className="finishedCover">
        <h1 className="finishedCover--title">Journey Complete</h1>

        <div
          className="finishedCover--links"
          onClick={() => {
            dispatch(setSoundSrc(`/assets/audio/yin-yang${state.system.iOS ? '_ios' : ''}.aac`))
            setRedirection('/yin-yang')
          }}
        >
          Check out the Yin/Yang legend
        </div>
        <div
          className="finishedCover--links"
          onClick={() => {
            setRedirection('/about')
          }}
        >
          Check out the about page
        </div>
        <div
          className="finishedCover--links"
          onClick={() => {
            dispatch(resetState())
            setRedirection('/home')
          }}
        >
          Start over
        </div>
        <div className="finishedCover--footer">
          <div className="finishedCover--donate">
            <a
              href="https://www.canadahelps.org/en/charities/dreamwalker-dance-company/?mprompt=1"
              target="_blank"
              rel="noreferrer"
            >
              Donate to Dreamwalker
            </a>
          </div>
          <div className="finishedCover--mailinglist">
            <div>Join our mailing list</div>
            <div id="mc_embed_signup">
              <form
                action="https://dreamwalkerdance.us8.list-manage.com/subscribe/post?u=7c191cabd2f37d117ab74d40d&amp;id=304d754a37&amp;f_id=00f400e0f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                novalidate
              >
                <div id="mc_embed_signup_scroll">
                  <div className="mc-field-group">
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      placeholder="Email Address"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                    />
                    <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                  </div>
                  <div id="mce-responses" className="clear foot">
                    <div className="response finishedCover--hidden" id="mce-error-response"></div>
                    <div className="response finishedCover--hidden" id="mce-success-response"></div>
                  </div>
                  <div className="finishedCover--outside" aria-hidden="true">
                    <input
                      type="text"
                      name="b_7c191cabd2f37d117ab74d40d_304d754a37"
                      tabindex="-1"
                    />
                  </div>
                  <div className="optionalParent">
                    <div className="clear foot">
                      <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinishedCover
